/* .home-banner-01 {
  background-image: url("../../Images/3DD.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.text-center {
  text-align: center;
}
/* .baground {
  background-image: url(../../Images/desktop.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
} */
h2 {
  -webkit-text-stroke: 1px white; /* width and color */

  /* font-family: sans; */
  /* color: yellow; */
}
.d-flex {
  justify-content: center;
}
.text-about {
  justify-content: space-between;
}
