.bgco {
  background-color: darkslateblue;
}
.Cir {
  height: 250px;
}
nav Nav.Link a {
  color: red;
}
/* * {
  margin: 0%;
  padding: 0%;
  max-width: 100%;
}
.container navbar {
  width: 1px;
} */
/* .bgmyred {
  background-color: red;
}
.logo {
  width: 200px;
  height: 60px;
} */
/* .navE {
  text-align: center;
} */
/* @include media-breakpoint-up(md) {
  
} */

/* @media (min-width: 992px) {
  .navE {
    text-align: end;
  }
} */
