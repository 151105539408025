/* body {
  background-color: rgb(231, 231, 231);
} */
* {
  padding: 0%;
  margin: 0%;
  max-width: 100%;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
  /* margin-top: 60px; */
}
.grid-item {
  margin: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
@media (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* margin-top: 60px; */
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    /* background-color: #2196F3; */
    padding: 10px;
    /* margin-top: 60px; */
  }
}
.All {
  margin-top: 60px;
  padding-top: 40px;
}
.All p {
  font-weight: 600;
  font-size: 24px;
}

/* .bottom {
  width: 550px;
  height: 500px;
  display: flex;
} */
/* * {
  margin: 0%;
  padding: 0%;
}
img {
  max-width: 100%;
} */
/* p { */
/* padding-top: 50px; */

/* text-align: center;
  white-space: nowrap;
} */
/* #work { */
/* margin-left: 10px; */
/* margin-top: 100px; */
/* display: block;
} */
/* .port {
  margin-top: 100px;
} */ ;
