* {
  margin: 0%;
  padding: 0%;
  max-width: 100%;
  max-height: 100%;
}
/* body {
  overflow-x: hidden;
} */
.main {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  display: inline-block;
}
/* .space {
  justify-content: space-between;
} */
/* .font-weight-light h6 {
  font-weight: 400;
} */
.view {
  /* width: 550px; */
  height: 550px;
  /* margin-left: 5%; */
}
.text-about {
  width: 550px;
  /* text-align: center; */
  /* margin: auto 5%; */
  /* height: 550px; */
}
/* .hello {
  margin-top: 30px;
} */

/* align-items: center; */
/* @keyframes About {
  from {
    background-color: red;
  }
  to {
    background-color: yellow;
  }
} */

/* The element to apply the animation to */
/* .text-about h2 {
  width: 100px;
  height: 100px;
  background-color: red;
  animation-name: About;
  animation-duration: 4s;
} */

@media (min-width: 992px) {
  .main {
    display: flex;
    /* position: relative; */
    /* margin: 70px, auto; */
    justify-content: space-between;
  }
}
