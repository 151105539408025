/*----------------------------------
Table of contents
----------------------------------*/
/*
1) variable
2) mixin
3) button
4) base
5) color
6) header
7) mega-menu
8) footer
9) banner
10) title
11) effect
12) feature-box
13) video
14) portfolio
15) process-bar
16) testimonial
17) team
18) client
19) accordion
20) price-table
21) blog
22) social
23) form
24) list
25) space
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
/*----------------------------------
* Buttons
----------------------------------*/
.m-btn-link {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: #f34236;
  border-bottom: 2px solid currentColor;
}
.m-btn-link:hover {
  color: #111111;
}

.m-btn {
  display: inline-block;
  border: 1px solid transparent;
  color: inherit;
  line-height: inherit;
  border-radius: 0;
  width: auto;
  padding: 12px 25px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  -moz-transition: ease-in-out all 0.55s;
  -o-transition: ease-in-out all 0.55s;
  -webkit-transition: ease-in-out all 0.55s;
  transition: ease-in-out all 0.55s;
  text-align: center;
  border-radius: 3px;
}
.m-btn.m-btn-xsm {
  font-size: 10px;
  padding: 2px 17px;
}
.m-btn.m-btn-sm {
  font-size: 11px;
  padding: 4px 24px;
}
.m-btn.m-btn-lg {
  font-size: 13px;
  padding: 12px 34px;
  line-height: 25px;
}
.m-btn.m-btn-xl {
  font-size: 15px;
  padding: 15px 40px;
  line-height: 25px;
}
.m-btn.m-btn-theme {
  background: #f34236;
  color: #ffffff;
  border-color: #f34236;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.m-btn.m-btn-theme:hover {
  background: #111111;
  border-color: #111111;
  box-shadow: 0px 15px 20px rgba(243, 66, 54, 0.4);
}
.m-btn.m-btn-t-theme {
  background: transparent;
  color: #f34236;
  border-color: #f34236;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.m-btn.m-btn-t-theme:hover {
  background: #f34236;
  border-color: #f34236;
  color: #ffffff;
  box-shadow: 0px 15px 20px rgba(243, 66, 54, 0.4);
}
.m-btn.m-btn-dark {
  background: #111111;
  color: #ffffff;
  border-color: #111111;
  box-shadow: 0px 8px 15px rgba(17, 17, 17, 0.1);
}
.m-btn.m-btn-dark:hover {
  background: #f34236;
  border-color: #f34236;
  box-shadow: 0px 15px 20px rgba(243, 66, 54, 0.4);
}
.m-btn.m-btn-t-dark {
  background: transparent;
  color: #111111;
  border-color: #111111;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.m-btn.m-btn-t-dark:hover {
  background: #111111;
  border-color: #111111;
  color: #ffffff;
  box-shadow: 0px 15px 20px rgba(17, 17, 17, 0.4);
}
.m-btn.m-btn-white {
  background: #ffffff;
  color: #f34236;
  border-color: #ffffff;
  box-shadow: 0px 8px 15px rgba(17, 17, 17, 0.1);
}
.m-btn.m-btn-white:hover {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
  box-shadow: 0px 15px 20px rgba(17, 17, 17, 0.4);
}
.m-btn.m-btn-t-white {
  background: transparent;
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 8px 15px rgba(17, 17, 17, 0.1);
}
.m-btn.m-btn-t-white:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #111111;
  box-shadow: 0px 15px 20px rgba(17, 17, 17, 0.4);
}

/* Icon
----------------*/
/* ----------------------
*	Team Icon
-------------------------*/
.social-icon a {
  margin: 0 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  text-align: center;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.social-icon.si-40 a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
.social-icon.si-theme a {
  background: #f34236;
}
.social-icon.si-theme a:hover {
  background: #111111;
  color: #ffffff;
}
.social-icon.si-white a {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #111111;
}
.social-icon.si-white a:hover {
  background: transparent;
  color: #ffffff;
}
.social-icon.si-t-white a {
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.social-icon.si-t-white a:hover {
  background: #ffffff;
  color: #111111;
}
.social-icon.si-dark a {
  background: #111111;
  border: 1px solid #111111;
  color: #ffffff;
}
.social-icon.si-dark a:hover {
  background: transparent;
  color: #111111;
}
.social-icon.si-text a {
  color: #929daf;
}
.social-icon.si-text a:hover {
  color: #757575;
}
.social-icon.si-inline a {
  width: auto;
  height: auto;
  line-height: normal;
  margin-right: 10px;
  font-size: 15px;
}
.social-icon.si-round a {
  border-radius: 50%;
}
.social-icon.si-radisu a {
  border-radius: 3px;
}

.video-btn {
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  z-index: 1;
  border: none;
}
.video-btn:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
  pointer-events: none;
  z-index: -1;
}
.video-btn.white {
  background: #ffffff;
}
.video-btn.white:after {
  background: #ffffff;
}
.video-btn.white span {
  color: #f34236;
}
.video-btn.theme {
  background: #f34236;
}
.video-btn.theme:after {
  background: #f34236;
}
.video-btn.theme span {
  color: #ffffff;
}
.video-btn span {
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid currentcolor;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 9px;
  right: 0;
  margin: auto;
  width: 0px;
  height: 0px;
}

@keyframes pulse-border {
  0% {
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
/* ------------------------
*	General
------------------------*/
/* body {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #828282;
  font-weight: 400;
  line-height: 1.6;
  font-smoothing: antialiased;
}

html {
  overflow-x: hidden;
}

a {
  color: #f34236;
} */
/* a:hover {
  color: #f11e0f;
  text-decoration: none;
} */

img {
  max-width: 100%;
}

/* .fa,
.fab,
.fal,
.far,
.fas {
  line-height: inherit;
} */

mark {
  background-image: linear-gradient(#7ef6e7, #81f1e7);
  background-size: 100% 34%;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

/* ----------------------
*	Loading
---------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f34236;
  z-index: 99999;
}

.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}
.load-circle span {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.load-circle span:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  border-color: #ffffff transparent #ffffff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------
*Heading fonts size
---------------------------*/
.h1,
h1 {
  font-size: 50px;
}
@media (max-width: 991px) {
  .h1,
  h1 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 35px;
  }
}

.h2,
h2 {
  font-size: 44px;
}
@media (max-width: 991px) {
  .h2,
  h2 {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .h2,
  h2 {
    font-size: 32px;
  }
}

.h3,
h3 {
  font-size: 32px;
}
@media (max-width: 991px) {
  .h3,
  h3 {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .h3,
  h3 {
    font-size: 24px;
  }
}

.h4,
h4 {
  font-size: 26px;
}
@media (max-width: 767px) {
  .h4,
  h4 {
    font-size: 22px;
  }
}

.h5,
h5 {
  font-size: 20px;
}

.h6,
h6 {
  font-size: 18px;
}

/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #f34236;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  border-radius: 50%;
  margin: 0 5px;
}
.owl-carousel-white .owl-dots .owl-dot {
  border-color: #ffffff;
  background: none;
}
.owl-dots .owl-dot.active {
  background: #f34236;
}
.owl-carousel-white .owl-dots .owl-dot.active {
  background: #ffffff;
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

/* Background color
-------------------------------*/
.theme-bg {
  background-color: #f34236;
}

.dark-bg {
  background-color: #111111;
}

.gray-bg {
  background-color: #f3f3f3;
}

.white-bg {
  background-color: #ffffff;
}

.blue-bg {
  background-color: #49bedf;
}

/* Text color
-------------------------------*/
/* .white-color {
  color: #ffffff;
}

.white-color-light {
  color: rgba(255, 255, 255, 0.65);
}

.dark-color {
  color: #111111;
}

.body-color {
  color: #828282;
}

.theme-color {
  color: #f34236;
}

.green-color {
  color: #11e279;
} */

/*	Toggle Menu
-------------------------*/
.navbar-toggler {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0;
  border-radius: 0;
  padding: 0;
  margin-left: 15px;
  background: #f34236;
  border: none;
}
.header-transparent-white .navbar-toggler {
  background: #ffffff;
}
.navbar-toggler span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 2px;
  margin: auto;
  box-shadow: 0px -8px 0 0px currentColor, 0px 8px 0 0px currentColor;
  background: #ffffff;
  color: #ffffff;
}
.header-transparent-white .navbar-toggler span {
  background: #111111;
  color: #111111;
}

.header-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  z-index: 10;
}
.header-nav.fixed-header {
  background: #ffffff;
  box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 16px;
}

.main-navbar {
  padding: 0;
}
.main-navbar .logo-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
  display: inline-block;
}
.fixed-header .main-navbar .logo-text {
  color: #f34236;
}
.main-navbar .logo-dark {
  display: none;
}
.fixed-header .main-navbar .logo-dark {
  display: block;
}
.fixed-header .main-navbar .logo-light {
  display: none;
}
@media (min-width: 992px) {
  .main-navbar .navbar-nav > li {
    margin: 0 0 0 30px;
  }
  .main-navbar .navbar-nav > li.mm-in > a {
    position: relative;
    padding-right: 15px;
  }
  .main-navbar .navbar-nav > li.mm-in > a:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 0;
    top: -5px;
    bottom: 0;
    margin: auto;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .main-navbar .navbar-nav > li.mm-in > a:after {
    display: none;
  }
}
@media (min-width: 992px) {
  .main-navbar .navbar-nav > li > a {
    font-size: 15px;
    font-weight: 600;
    padding: 0 !important;
    line-height: 70px;
    display: block;
    color: #ffffff;
    position: relative;
  }
  .fixed-header .main-navbar .navbar-nav > li > a {
    color: #111111;
  }
  .main-navbar .navbar-nav > li > a:after {
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 0px;
    -moz-transition: ease all 0.35s;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
    height: 3px;
    background: #ffffff;
  }
  .fixed-header .main-navbar .navbar-nav > li > a:after {
    background: #f34236;
  }
  .fixed-header .main-navbar .navbar-nav > li > a:hover,
  .fixed-header .main-navbar .navbar-nav > li > a.active {
    color: #111111;
  }
  .main-navbar .navbar-nav > li > a:hover:after,
  .main-navbar .navbar-nav > li > a.active:after {
    width: 100%;
    right: auto;
    left: 0;
  }
}

/* Side Menu
-----------------------------*/
.header-nav-left {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200px;
  overflow: hidden;
  background: #111111;
}
.header-nav-left .scroll-in {
  overflow: auto;
  height: 100vh;
  width: 220px;
  padding-right: 20px;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.header-nav-left .scroll-in::-webkit-scrollbar {
  width: 0 !important;
}
.header-nav-left .navbar-collapse {
  display: block;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.header-nav-left .navbar-toggler {
  display: none;
}
.header-nav-left .header-logo {
  width: 100%;
}
.header-nav-left .header-logo a {
  display: block;
}
.header-nav-left .header-logo label {
  width: 100%;
  background: #111111;
  margin: 0;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}
.header-nav-left .navbar-nav > li .nav-link {
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
.header-nav-left .navbar-nav > li .nav-link i {
  width: 22px;
}
.header-nav-left .navbar-nav > li .nav-link.active {
  background: #f34236;
}
.header-nav-left .navbar-nav > li:hover .nav-link {
  background: #f34236;
}

/* Responsive Menu
-----------------------------*/
@media (max-width: 991px) {
  .main-navbar {
    padding: 10px 15px;
  }
  .main-navbar > .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-navbar .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
    max-height: 80vh;
    overflow-y: auto;
  }
  .main-navbar .navbar-nav > li {
    margin: 0;
    border-top: 1px solid #e6e6e6;
  }
  .main-navbar .navbar-nav > li > a {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    padding: 0 15px;
    line-height: 40px;
    display: block;
    color: #111111;
  }
  .main-navbar .navbar-nav > li > a.active {
    color: #f34236;
  }
}
/* Desktop
--------------------*/
@media (min-width: 992px) {
  .simple-dropdown {
    position: relative;
  }
  .simple-dropdown .m-dropdown-toggle {
    display: none;
  }
  #navbar-collapse-toggle ul li a {
    color: #111111;
    font-size: 18px;
  }
  #navbar-collapse-toggle ul li a:hover {
    color: rgb(9, 34, 145);
  }
  .simple-dropdown .m-dropdown-menu {
    position: absolute;
    top: 110%;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 26px 21px 0px rgba(0, 0, 0, 0.1);
    width: 180px;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    pointer-events: none;
    -moz-transition: ease all 0.55s;
    -o-transition: ease all 0.55s;
    -webkit-transition: ease all 0.55s;
    transition: ease all 0.55s;
    opacity: 0;
  }
  .simple-dropdown .m-dropdown-menu > li > a {
    color: #111111;
    padding: 8px 15px;
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
  .simple-dropdown .m-dropdown-menu > li > a i {
    font-size: 18px;
    vertical-align: middle;
    width: 24px;
    display: inline-block;
  }
  .simple-dropdown .m-dropdown-menu > li:hover > a {
    color: #f34236;
  }
  .simple-dropdown:hover .m-dropdown-menu {
    opacity: 1;
    top: 100%;
    pointer-events: auto;
  }

  .m-mega-menu {
    position: static !important;
  }
  .m-mega-menu .m-dropdown-mega-menu {
    width: 100%;
    padding: 10px 25px;
  }
  .m-mega-menu .mm-link {
    margin: 0;
    padding: 15px 0;
  }
  .m-mega-menu .mm-link li {
    list-style: none;
    position: relative;
    padding: 6px 0;
  }
  .m-mega-menu .mm-link li > a {
    color: #111111;
    padding: 8px 15px;
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
  .m-mega-menu .mm-link li > a i {
    font-size: 14px;
    vertical-align: middle;
    width: 24px;
    display: inline-block;
  }
  .m-mega-menu .mm-link li:hover > a {
    color: #f34236;
  }
}
/* Mobile
--------------------*/
@media (max-width: 991px) {
  .m-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-left: 1px solid #e6e6e6;
    color: #111111;
  }

  .simple-dropdown {
    position: relative;
  }
  .simple-dropdown .m-dropdown-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    padding: 0;
    background: #f5f5f5;
    border-top: 1px solid #e6e6e6;
  }
  .simple-dropdown .m-dropdown-menu > li {
    padding-left: 10px;
  }
  .simple-dropdown .m-dropdown-menu > li:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
  .simple-dropdown .m-dropdown-menu > li > a {
    display: block;
    padding: 0 15px;
    font-size: 13px;
    font-weight: 600;
    line-height: 40px;
    color: #111111;
  }
  .simple-dropdown .m-dropdown-menu > li > a:hover {
    color: #f34236;
  }
  .simple-dropdown .m-dropdown-menu .container {
    padding: 0;
  }
  .simple-dropdown .m-dropdown-menu .mm-link {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .simple-dropdown .m-dropdown-menu .mm-link > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .simple-dropdown .m-dropdown-menu .mm-link > li > a {
    display: block;
    padding: 10px 0 10px 25px;
    font-size: 15px;
    color: #9d9d9d;
    vertical-align: top;
    position: relative;
  }
  .simple-dropdown .m-dropdown-menu .mm-link > li > a i {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 13px;
    opacity: 0.6;
  }
  .simple-dropdown .m-dropdown-menu .mm-link > li > a.active {
    color: #ffffff;
  }
  .simple-dropdown.open-menu-parent .m-dropdown-menu {
    display: block;
  }
}
/* } */
.footer.footer-dark {
  background: #212121;
}

.footer-top {
  padding: 60px 0;
}
@media (max-width: 991px) {
  .footer-top {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  .footer-top {
    padding: 30px 0;
  }
}
.footer-dark .footer-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
.footer-top .fot-title {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 30px;
}
.footer-dark .footer-top .fot-title {
  color: #ffffff;
}
.footer-top .nav li:not(:first-child) {
  margin-top: 8px;
}
.footer-top .nav a {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}
.footer-dark .footer-top .nav a {
  color: #999999;
}
.footer-dark .footer-top .nav a:hover {
  color: #ffffff;
}
.footer-top .fot-logo {
  color: #ffffff;
  padding-bottom: 25px;
  font-size: 43px;
  font-weight: 900;
  line-height: 43px;
}
.footer-top address p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  position: relative;
  padding-left: 26px;
}
.footer-top address p i {
  position: absolute;
  top: 3px;
  left: 0;
}
.footer-dark .footer-top address p {
  color: #999999;
}
.footer-dark .footer-top address p a {
  color: #999999;
}
.footer-dark .footer-top address p a:hover {
  color: #ffffff;
}
.footer-top .insta-row {
  margin-left: -5px;
  margin-right: -5px;
}
.footer-top .insta-row .col-4 {
  padding: 0px 5px 10px;
}

.footer-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-bottom .nav li {
  margin-right: 15px;
}
.footer-dark .footer-bottom .nav li a {
  color: #999999;
}
.footer-dark .footer-bottom .nav li a:hover {
  color: #ffffff;
}
.footer-bottom p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .footer-bottom p {
    margin-bottom: 10px;
  }
}
.footer-dark .footer-bottom p {
  color: #999999;
}

.section-title h2 {
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
/* .section-title h2:after {
  width: 60px;
  height: 3px;
  background: #111111;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
} */
.section-title.left h2:after {
  right: auto;
}
.section-title h3 {
  font-weight: 700;
  font-size: 35px;
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.section-title p {
  margin: 15px 0 0;
}
.section-title h4 {
  font-weight: 700;
  font-size: 35px;
  margin: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 0 15px;
}
.section-title h4 .st-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 60px;
  height: 2px;
  background: #f34236;
  margin: auto;
}
.section-title h4 .st-line:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  border: 2px solid #f34236;
}

.sm-title {
  position: relative;
}
.sm-title h5 {
  font-size: 28px;
  font-weight: 600;
  position: relative;
}
.sm-title i {
  position: absolute;
  font-size: 60px;
  top: -9px;
  right: 0;
  opacity: 0.1;
}

.page-title {
  padding: 160px 0 100px;
}
.page-title h1,
.page-title .h1 {
  font-weight: 700;
  margin-bottom: 20px;
}

/* ----------------------
*	Sub Title
---------------------------*/
.sub-title h3 {
  font-size: 20px;
  margin: 0 0 25px;
  padding: 0 0 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}

/* Breadcrumbs
--------------------------------*/
.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  border-radius: 0;
}
.breadcrumb li {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #111111;
}
.breadcrumb li + li {
  padding-left: 17px;
  margin-left: 17px;
}
.breadcrumb li + li:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
  opacity: 0.5;
  left: -2px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.breadcrumb li a {
  color: #111111;
}
.breadcrumb.white li {
  color: #ffffff;
}
.breadcrumb.white li a {
  color: #ffffff;
}

.skill-lt:not(:last-child) {
  margin-bottom: 30px;
}
.skill-lt h6 {
  font-size: 16px;
  margin: 0 0 10px;
}
.skill-lt .skill-bar {
  position: relative;
  background: #eee;
}
.skill-lt .skill-bar .skill-bar-in {
  width: 0px;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  height: 5px;
  position: relative;
}
.skill-lt .skill-bar .skill-bar-in span {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 14px;
}
.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px;
}
.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px;
}
.skill-lt.light .skill-bar {
  background: rgba(0, 0, 0, 0.5);
}

/* ----------------------
*	Section
-------------------------*/
.container-large {
  max-width: 1440px;
}

.container-small {
  max-width: 750px;
}

.section {
  padding: 100px 0;
}
@media (max-width: 991px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-top-up .container {
  margin-top: -190px;
  position: relative;
  z-index: 1;
}

.section-top-up-100 .container {
  margin-top: -100px;
  position: relative;
  z-index: 1;
}

/* ----------------------
*	Box shadow
---------------------------*/
.box-shadow-lg {
  box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 16px;
}

.box-shadow {
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
}

.box-shadow-hover {
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
}
.box-shadow-hover:hover {
  box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 16px;
}

.box-shadow-only-hover:hover {
  box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 16px;
}

.border-radius-50 {
  border-radius: 50%;
}

/* ----------------------
*	Opacity
---------------------------*/
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

/* ----------------------
*	transeition
---------------------------*/
.transition {
  -moz-transition: ease-in-out all 0.35s;
  -o-transition: ease-in-out all 0.35s;
  -webkit-transition: ease-in-out all 0.35s;
  transition: ease-in-out all 0.35s;
}

/* ----------------------
*	Image Hover
---------------------------*/
.img-hover .img-hover-in {
  overflow: hidden;
}
.img-hover .img-hover-in img {
  -moz-transition: ease-in-out all 0.33s;
  -o-transition: ease-in-out all 0.33s;
  -webkit-transition: ease-in-out all 0.33s;
  transition: ease-in-out all 0.33s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.img-hover:hover .img-hover-in img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* ----------------------
*	Top Hover
---------------------------*/
.hover-top {
  position: relative;
  top: 0;
}
.hover-top:hover {
  top: -10px;
}

/* ----------------------
*	Grayscale Hover
---------------------------*/
.grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.grayscale-hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.grayscale-hover:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.font-alt {
  font-family: "Poppins", sans-serif;
}

/*	Property
---------------------------*/
.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-center {
  background-position: center;
}

/* Letter spacing
-------------------------------*/
.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.letter-spacing-6 {
  letter-spacing: 6px;
}

.letter-spacing-7 {
  letter-spacing: 7px;
}

.letter-spacing-8 {
  letter-spacing: 8px;
}

.letter-spacing-9 {
  letter-spacing: 9px;
}

.letter-spacing-10 {
  letter-spacing: 10px;
}

/* Height Width
--------------------*/
.w-5px {
  width: 5px;
}

.w-10px {
  width: 10px;
}

.w-15px {
  width: 15px;
}

.w-20px {
  width: 20px;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-45px {
  width: 45px;
}

.w-50px {
  width: 50px;
}

.w-55px {
  width: 55px;
}

.w-60px {
  width: 60px;
}

.w-65px {
  width: 65px;
}

.w-70px {
  width: 70px;
}

.w-75px {
  width: 75px;
}

.w-80px {
  width: 80px;
}

.w-85px {
  width: 85px;
}

.w-90px {
  width: 90px;
}

.w-95px {
  width: 95px;
}

.w-100px {
  width: 100px;
}

.w-150px {
  width: 150px;
}

.w-200px {
  width: 200px;
}

.w-250px {
  width: 250px;
}

.w-300px {
  width: 300px;
}

.w-350px {
  width: 350px;
}

.w-400px {
  width: 400px;
}

.w-450px {
  width: 450px;
}

.w-500px {
  width: 500px;
}

.w-550px {
  width: 550px;
}

.w-600px {
  width: 600px;
}

.w-650px {
  width: 650px;
}

.w-700px {
  width: 700px;
}

.w-750px {
  width: 750px;
}

.w-800px {
  width: 800px;
}

.w-850px {
  width: 850px;
}

.w-900px {
  width: 900px;
}

.w-950px {
  width: 950px;
}

.w-1000px {
  width: 1000px;
}

.max-w-100 {
  max-width: 100%;
}

.min-h-350px {
  min-height: 350px;
}

.min-h-50vw {
  min-height: 65vh;
}

.full-screen {
  min-height: 100vh;
}

.screen-85 {
  min-height: 85vh;
}

.screen-65 {
  min-height: 65vh;
}

@media (max-width: 767px) {
  .sm-full-screen-auto {
    min-height: auto;
  }
}
.max-width-auto {
  max-width: none;
}
@media (max-width: 991px) {
  .max-width-auto {
    max-width: 100%;
  }
}

/* Font wight
--------------------*/
.font-w-100 {
  font-weight: 100;
}

.font-w-200 {
  font-weight: 200;
}

.font-w-300 {
  font-weight: 300;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-800 {
  font-weight: 800;
}

.font-w-900 {
  font-weight: 900;
}

/* Margin Padding
--------------------*/
.m-0px {
  margin: 0px;
}

.m-0px-tb {
  margin-top: 0px;
  margin-bottom: 0px;
}

.m-0px-lr {
  margin-left: 0px;
  margin-right: 0px;
}

.m-0px-t {
  margin-top: 0px;
}

.m-0px-l {
  margin-left: 0px;
}

.m-0px-r {
  margin-right: 0px;
}

.m-0px-b {
  margin-bottom: 0px;
}

.p-0px {
  padding: 0px;
}

.p-0px-tb {
  padding-top: 0px;
  padding-bottom: 0px;
}

.p-0px-lr {
  padding-left: 0px;
  padding-right: 0px;
}

.p-0px-t {
  padding-top: 0px;
}

.p-0px-l {
  padding-left: 0px;
}

.p-0px-r {
  padding-right: 0px;
}

.p-0px-b {
  padding-bottom: 0px;
}

.m-5px {
  margin: 5px;
}

.m-5px-tb {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-5px-lr {
  margin-left: 5px;
  margin-right: 5px;
}

.m-5px-t {
  margin-top: 5px;
}

.m-5px-l {
  margin-left: 5px;
}

.m-5px-r {
  margin-right: 5px;
}

.m-5px-b {
  margin-bottom: 5px;
}

.p-5px {
  padding: 5px;
}

.p-5px-tb {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-5px-lr {
  padding-left: 5px;
  padding-right: 5px;
}

.p-5px-t {
  padding-top: 5px;
}

.p-5px-l {
  padding-left: 5px;
}

.p-5px-r {
  padding-right: 5px;
}

.p-5px-b {
  padding-bottom: 5px;
}

.m-10px {
  margin: 10px;
}

.m-10px-tb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-10px-lr {
  margin-left: 10px;
  margin-right: 10px;
}

.m-10px-t {
  margin-top: 10px;
}

.m-10px-l {
  margin-left: 10px;
}

.m-10px-r {
  margin-right: 10px;
}

.m-10px-b {
  margin-bottom: 10px;
}

.p-10px {
  padding: 10px;
}

.p-10px-tb {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-10px-lr {
  padding-left: 10px;
  padding-right: 10px;
}

.p-10px-t {
  padding-top: 10px;
}

.p-10px-l {
  padding-left: 10px;
}

.p-10px-r {
  padding-right: 10px;
}

.p-10px-b {
  padding-bottom: 10px;
}

.m-15px {
  margin: 15px;
}

.m-15px-tb {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-15px-lr {
  margin-left: 15px;
  margin-right: 15px;
}

.m-15px-t {
  margin-top: 15px;
}

.m-15px-l {
  margin-left: 15px;
}

.m-15px-r {
  margin-right: 15px;
}

.m-15px-b {
  margin-bottom: 15px;
}

.p-15px {
  padding: 15px;
}

.p-15px-tb {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-15px-lr {
  padding-left: 15px;
  padding-right: 15px;
}

.p-15px-t {
  padding-top: 15px;
}

.p-15px-l {
  padding-left: 15px;
}

.p-15px-r {
  padding-right: 15px;
}

.p-15px-b {
  padding-bottom: 15px;
}

.m-20px {
  margin: 20px;
}

.m-20px-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-20px-lr {
  margin-left: 20px;
  margin-right: 20px;
}

.m-20px-t {
  margin-top: 20px;
}

.m-20px-l {
  margin-left: 20px;
}

.m-20px-r {
  margin-right: 20px;
}

.m-20px-b {
  margin-bottom: 20px;
}

.p-20px {
  padding: 20px;
}

.p-20px-tb {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-20px-lr {
  padding-left: 20px;
  padding-right: 20px;
}

.p-20px-t {
  padding-top: 20px;
}

.p-20px-l {
  padding-left: 20px;
}

.p-20px-r {
  padding-right: 20px;
}

.p-20px-b {
  padding-bottom: 20px;
}

.m-25px {
  margin: 25px;
}

.m-25px-tb {
  margin-top: 25px;
  margin-bottom: 25px;
}

.m-25px-lr {
  margin-left: 25px;
  margin-right: 25px;
}

.m-25px-t {
  margin-top: 25px;
}

.m-25px-l {
  margin-left: 25px;
}

.m-25px-r {
  margin-right: 25px;
}

.m-25px-b {
  margin-bottom: 25px;
}

.p-25px {
  padding: 25px;
}

.p-25px-tb {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-25px-lr {
  padding-left: 25px;
  padding-right: 25px;
}

.p-25px-t {
  padding-top: 25px;
}

.p-25px-l {
  padding-left: 25px;
}

.p-25px-r {
  padding-right: 25px;
}

.p-25px-b {
  padding-bottom: 25px;
}

.m-30px {
  margin: 30px;
}

.m-30px-tb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-30px-lr {
  margin-left: 30px;
  margin-right: 30px;
}

.m-30px-t {
  margin-top: 30px;
}

.m-30px-l {
  margin-left: 30px;
}

.m-30px-r {
  margin-right: 30px;
}

.m-30px-b {
  margin-bottom: 30px;
}

.p-30px {
  padding: 30px;
}

.p-30px-tb {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-30px-lr {
  padding-left: 30px;
  padding-right: 30px;
}

.p-30px-t {
  padding-top: 30px;
}

.p-30px-l {
  padding-left: 30px;
}

.p-30px-r {
  padding-right: 30px;
}

.p-30px-b {
  padding-bottom: 30px;
}

.m-35px {
  margin: 35px;
}

.m-35px-tb {
  margin-top: 35px;
  margin-bottom: 35px;
}

.m-35px-lr {
  margin-left: 35px;
  margin-right: 35px;
}

.m-35px-t {
  margin-top: 35px;
}

.m-35px-l {
  margin-left: 35px;
}

.m-35px-r {
  margin-right: 35px;
}

.m-35px-b {
  margin-bottom: 35px;
}

.p-35px {
  padding: 35px;
}

.p-35px-tb {
  padding-top: 35px;
  padding-bottom: 35px;
}

.p-35px-lr {
  padding-left: 35px;
  padding-right: 35px;
}

.p-35px-t {
  padding-top: 35px;
}

.p-35px-l {
  padding-left: 35px;
}

.p-35px-r {
  padding-right: 35px;
}

.p-35px-b {
  padding-bottom: 35px;
}

.m-40px {
  margin: 40px;
}

.m-40px-tb {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-40px-lr {
  margin-left: 40px;
  margin-right: 40px;
}

.m-40px-t {
  margin-top: 40px;
}

.m-40px-l {
  margin-left: 40px;
}

.m-40px-r {
  margin-right: 40px;
}

.m-40px-b {
  margin-bottom: 40px;
}

.p-40px {
  padding: 40px;
}

.p-40px-tb {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-40px-lr {
  padding-left: 40px;
  padding-right: 40px;
}

.p-40px-t {
  padding-top: 40px;
}

.p-40px-l {
  padding-left: 40px;
}

.p-40px-r {
  padding-right: 40px;
}

.p-40px-b {
  padding-bottom: 40px;
}

.m-45px {
  margin: 45px;
}

.m-45px-tb {
  margin-top: 45px;
  margin-bottom: 45px;
}

.m-45px-lr {
  margin-left: 45px;
  margin-right: 45px;
}

.m-45px-t {
  margin-top: 45px;
}

.m-45px-l {
  margin-left: 45px;
}

.m-45px-r {
  margin-right: 45px;
}

.m-45px-b {
  margin-bottom: 45px;
}

.p-45px {
  padding: 45px;
}

.p-45px-tb {
  padding-top: 45px;
  padding-bottom: 45px;
}

.p-45px-lr {
  padding-left: 45px;
  padding-right: 45px;
}

.p-45px-t {
  padding-top: 45px;
}

.p-45px-l {
  padding-left: 45px;
}

.p-45px-r {
  padding-right: 45px;
}

.p-45px-b {
  padding-bottom: 45px;
}

.m-50px {
  margin: 50px;
}

.m-50px-tb {
  margin-top: 50px;
  margin-bottom: 50px;
}

.m-50px-lr {
  margin-left: 50px;
  margin-right: 50px;
}

.m-50px-t {
  margin-top: 50px;
}

.m-50px-l {
  margin-left: 50px;
}

.m-50px-r {
  margin-right: 50px;
}

.m-50px-b {
  margin-bottom: 50px;
}

.p-50px {
  padding: 50px;
}

.p-50px-tb {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-50px-lr {
  padding-left: 50px;
  padding-right: 50px;
}

.p-50px-t {
  padding-top: 50px;
}

.p-50px-l {
  padding-left: 50px;
}

.p-50px-r {
  padding-right: 50px;
}

.p-50px-b {
  padding-bottom: 50px;
}

.m-55px {
  margin: 55px;
}

.m-55px-tb {
  margin-top: 55px;
  margin-bottom: 55px;
}

.m-55px-lr {
  margin-left: 55px;
  margin-right: 55px;
}

.m-55px-t {
  margin-top: 55px;
}

.m-55px-l {
  margin-left: 55px;
}

.m-55px-r {
  margin-right: 55px;
}

.m-55px-b {
  margin-bottom: 55px;
}

.p-55px {
  padding: 55px;
}

.p-55px-tb {
  padding-top: 55px;
  padding-bottom: 55px;
}

.p-55px-lr {
  padding-left: 55px;
  padding-right: 55px;
}

.p-55px-t {
  padding-top: 55px;
}

.p-55px-l {
  padding-left: 55px;
}

.p-55px-r {
  padding-right: 55px;
}

.p-55px-b {
  padding-bottom: 55px;
}

.m-60px {
  margin: 60px;
}

.m-60px-tb {
  margin-top: 60px;
  margin-bottom: 60px;
}

.m-60px-lr {
  margin-left: 60px;
  margin-right: 60px;
}

.m-60px-t {
  margin-top: 60px;
}

.m-60px-l {
  margin-left: 60px;
}

.m-60px-r {
  margin-right: 60px;
}

.m-60px-b {
  margin-bottom: 60px;
}

.p-60px {
  padding: 60px;
}

.p-60px-tb {
  padding-top: 60px;
  padding-bottom: 60px;
}

.p-60px-lr {
  padding-left: 60px;
  padding-right: 60px;
}

.p-60px-t {
  padding-top: 60px;
}

.p-60px-l {
  padding-left: 60px;
}

.p-60px-r {
  padding-right: 60px;
}

.p-60px-b {
  padding-bottom: 60px;
}

.m-65px {
  margin: 65px;
}

.m-65px-tb {
  margin-top: 65px;
  margin-bottom: 65px;
}

.m-65px-lr {
  margin-left: 65px;
  margin-right: 65px;
}

.m-65px-t {
  margin-top: 65px;
}

.m-65px-l {
  margin-left: 65px;
}

.m-65px-r {
  margin-right: 65px;
}

.m-65px-b {
  margin-bottom: 65px;
}

.p-65px {
  padding: 65px;
}

.p-65px-tb {
  padding-top: 65px;
  padding-bottom: 65px;
}

.p-65px-lr {
  padding-left: 65px;
  padding-right: 65px;
}

.p-65px-t {
  padding-top: 65px;
}

.p-65px-l {
  padding-left: 65px;
}

.p-65px-r {
  padding-right: 65px;
}

.p-65px-b {
  padding-bottom: 65px;
}

.m-70px {
  margin: 70px;
}

.m-70px-tb {
  margin-top: 70px;
  margin-bottom: 70px;
}

.m-70px-lr {
  margin-left: 70px;
  margin-right: 70px;
}

.m-70px-t {
  margin-top: 70px;
}

.m-70px-l {
  margin-left: 70px;
}

.m-70px-r {
  margin-right: 70px;
}

.m-70px-b {
  margin-bottom: 70px;
}

.p-70px {
  padding: 70px;
}

.p-70px-tb {
  padding-top: 70px;
  padding-bottom: 70px;
}

.p-70px-lr {
  padding-left: 70px;
  padding-right: 70px;
}

.p-70px-t {
  padding-top: 70px;
}

.p-70px-l {
  padding-left: 70px;
}

.p-70px-r {
  padding-right: 70px;
}

.p-70px-b {
  padding-bottom: 70px;
}

.m-75px {
  margin: 75px;
}

.m-75px-tb {
  margin-top: 75px;
  margin-bottom: 75px;
}

.m-75px-lr {
  margin-left: 75px;
  margin-right: 75px;
}

.m-75px-t {
  margin-top: 75px;
}

.m-75px-l {
  margin-left: 75px;
}

.m-75px-r {
  margin-right: 75px;
}

.m-75px-b {
  margin-bottom: 75px;
}

.p-75px {
  padding: 75px;
}

.p-75px-tb {
  padding-top: 75px;
  padding-bottom: 75px;
}

.p-75px-lr {
  padding-left: 75px;
  padding-right: 75px;
}

.p-75px-t {
  padding-top: 75px;
}

.p-75px-l {
  padding-left: 75px;
}

.p-75px-r {
  padding-right: 75px;
}

.p-75px-b {
  padding-bottom: 75px;
}

.m-80px {
  margin: 80px;
}

.m-80px-tb {
  margin-top: 80px;
  margin-bottom: 80px;
}

.m-80px-lr {
  margin-left: 80px;
  margin-right: 80px;
}

.m-80px-t {
  margin-top: 80px;
}

.m-80px-l {
  margin-left: 80px;
}

.m-80px-r {
  margin-right: 80px;
}

.m-80px-b {
  margin-bottom: 80px;
}

.p-80px {
  padding: 80px;
}

.p-80px-tb {
  padding-top: 80px;
  padding-bottom: 80px;
}

.p-80px-lr {
  padding-left: 80px;
  padding-right: 80px;
}

.p-80px-t {
  padding-top: 80px;
}

.p-80px-l {
  padding-left: 80px;
}

.p-80px-r {
  padding-right: 80px;
}

.p-80px-b {
  padding-bottom: 80px;
}

.m-85px {
  margin: 85px;
}

.m-85px-tb {
  margin-top: 85px;
  margin-bottom: 85px;
}

.m-85px-lr {
  margin-left: 85px;
  margin-right: 85px;
}

.m-85px-t {
  margin-top: 85px;
}

.m-85px-l {
  margin-left: 85px;
}

.m-85px-r {
  margin-right: 85px;
}

.m-85px-b {
  margin-bottom: 85px;
}

.p-85px {
  padding: 85px;
}

.p-85px-tb {
  padding-top: 85px;
  padding-bottom: 85px;
}

.p-85px-lr {
  padding-left: 85px;
  padding-right: 85px;
}

.p-85px-t {
  padding-top: 85px;
}

.p-85px-l {
  padding-left: 85px;
}

.p-85px-r {
  padding-right: 85px;
}

.p-85px-b {
  padding-bottom: 85px;
}

.m-90px {
  margin: 90px;
}

.m-90px-tb {
  margin-top: 90px;
  margin-bottom: 90px;
}

.m-90px-lr {
  margin-left: 90px;
  margin-right: 90px;
}

.m-90px-t {
  margin-top: 90px;
}

.m-90px-l {
  margin-left: 90px;
}

.m-90px-r {
  margin-right: 90px;
}

.m-90px-b {
  margin-bottom: 90px;
}

.p-90px {
  padding: 90px;
}

.p-90px-tb {
  padding-top: 90px;
  padding-bottom: 90px;
}

.p-90px-lr {
  padding-left: 90px;
  padding-right: 90px;
}

.p-90px-t {
  padding-top: 90px;
}

.p-90px-l {
  padding-left: 90px;
}

.p-90px-r {
  padding-right: 90px;
}

.p-90px-b {
  padding-bottom: 90px;
}

.m-95px {
  margin: 95px;
}

.m-95px-tb {
  margin-top: 95px;
  margin-bottom: 95px;
}

.m-95px-lr {
  margin-left: 95px;
  margin-right: 95px;
}

.m-95px-t {
  margin-top: 95px;
}

.m-95px-l {
  margin-left: 95px;
}

.m-95px-r {
  margin-right: 95px;
}

.m-95px-b {
  margin-bottom: 95px;
}

.p-95px {
  padding: 95px;
}

.p-95px-tb {
  padding-top: 95px;
  padding-bottom: 95px;
}

.p-95px-lr {
  padding-left: 95px;
  padding-right: 95px;
}

.p-95px-t {
  padding-top: 95px;
}

.p-95px-l {
  padding-left: 95px;
}

.p-95px-r {
  padding-right: 95px;
}

.p-95px-b {
  padding-bottom: 95px;
}

.m-100px {
  margin: 100px;
}

.m-100px-tb {
  margin-top: 100px;
  margin-bottom: 100px;
}

.m-100px-lr {
  margin-left: 100px;
  margin-right: 100px;
}

.m-100px-t {
  margin-top: 100px;
}

.m-100px-l {
  margin-left: 100px;
}

.m-100px-r {
  margin-right: 100px;
}

.m-100px-b {
  margin-bottom: 100px;
}

.p-100px {
  padding: 100px;
}

.p-100px-tb {
  padding-top: 100px;
  padding-bottom: 100px;
}

.p-100px-lr {
  padding-left: 100px;
  padding-right: 100px;
}

.p-100px-t {
  padding-top: 100px;
}

.p-100px-l {
  padding-left: 100px;
}

.p-100px-r {
  padding-right: 100px;
}

.p-100px-b {
  padding-bottom: 100px;
}

@media (max-width: 1200px) {
  /* Margin Padding
  --------------------*/
  .lg-m-0px {
    margin: 0px;
  }

  .lg-m-0px-tb {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lg-m-0px-lr {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg-m-0px-t {
    margin-top: 0px;
  }

  .lg-m-0px-l {
    margin-left: 0px;
  }

  .lg-m-0px-r {
    margin-right: 0px;
  }

  .lg-m-0px-b {
    margin-bottom: 0px;
  }

  .lg-p-0px {
    padding: 0px;
  }

  .lg-p-0px-tb {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg-p-0px-lr {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg-p-0px-t {
    padding-top: 0px;
  }

  .lg-p-0px-l {
    padding-left: 0px;
  }

  .lg-p-0px-r {
    padding-right: 0px;
  }

  .lg-p-0px-b {
    padding-bottom: 0px;
  }

  .lg-m-5px {
    margin: 5px;
  }

  .lg-m-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .lg-m-5px-lr {
    margin-left: 5px;
    margin-right: 5px;
  }

  .lg-m-5px-t {
    margin-top: 5px;
  }

  .lg-m-5px-l {
    margin-left: 5px;
  }

  .lg-m-5px-r {
    margin-right: 5px;
  }

  .lg-m-5px-b {
    margin-bottom: 5px;
  }

  .lg-p-5px {
    padding: 5px;
  }

  .lg-p-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .lg-p-5px-lr {
    padding-left: 5px;
    padding-right: 5px;
  }

  .lg-p-5px-t {
    padding-top: 5px;
  }

  .lg-p-5px-l {
    padding-left: 5px;
  }

  .lg-p-5px-r {
    padding-right: 5px;
  }

  .lg-p-5px-b {
    padding-bottom: 5px;
  }

  .lg-m-10px {
    margin: 10px;
  }

  .lg-m-10px-tb {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .lg-m-10px-lr {
    margin-left: 10px;
    margin-right: 10px;
  }

  .lg-m-10px-t {
    margin-top: 10px;
  }

  .lg-m-10px-l {
    margin-left: 10px;
  }

  .lg-m-10px-r {
    margin-right: 10px;
  }

  .lg-m-10px-b {
    margin-bottom: 10px;
  }

  .lg-p-10px {
    padding: 10px;
  }

  .lg-p-10px-tb {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .lg-p-10px-lr {
    padding-left: 10px;
    padding-right: 10px;
  }

  .lg-p-10px-t {
    padding-top: 10px;
  }

  .lg-p-10px-l {
    padding-left: 10px;
  }

  .lg-p-10px-r {
    padding-right: 10px;
  }

  .lg-p-10px-b {
    padding-bottom: 10px;
  }

  .lg-m-15px {
    margin: 15px;
  }

  .lg-m-15px-tb {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .lg-m-15px-lr {
    margin-left: 15px;
    margin-right: 15px;
  }

  .lg-m-15px-t {
    margin-top: 15px;
  }

  .lg-m-15px-l {
    margin-left: 15px;
  }

  .lg-m-15px-r {
    margin-right: 15px;
  }

  .lg-m-15px-b {
    margin-bottom: 15px;
  }

  .lg-p-15px {
    padding: 15px;
  }

  .lg-p-15px-tb {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .lg-p-15px-lr {
    padding-left: 15px;
    padding-right: 15px;
  }

  .lg-p-15px-t {
    padding-top: 15px;
  }

  .lg-p-15px-l {
    padding-left: 15px;
  }

  .lg-p-15px-r {
    padding-right: 15px;
  }

  .lg-p-15px-b {
    padding-bottom: 15px;
  }

  .lg-m-20px {
    margin: 20px;
  }

  .lg-m-20px-tb {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .lg-m-20px-lr {
    margin-left: 20px;
    margin-right: 20px;
  }

  .lg-m-20px-t {
    margin-top: 20px;
  }

  .lg-m-20px-l {
    margin-left: 20px;
  }

  .lg-m-20px-r {
    margin-right: 20px;
  }

  .lg-m-20px-b {
    margin-bottom: 20px;
  }

  .lg-p-20px {
    padding: 20px;
  }

  .lg-p-20px-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lg-p-20px-lr {
    padding-left: 20px;
    padding-right: 20px;
  }

  .lg-p-20px-t {
    padding-top: 20px;
  }

  .lg-p-20px-l {
    padding-left: 20px;
  }

  .lg-p-20px-r {
    padding-right: 20px;
  }

  .lg-p-20px-b {
    padding-bottom: 20px;
  }

  .lg-m-25px {
    margin: 25px;
  }

  .lg-m-25px-tb {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .lg-m-25px-lr {
    margin-left: 25px;
    margin-right: 25px;
  }

  .lg-m-25px-t {
    margin-top: 25px;
  }

  .lg-m-25px-l {
    margin-left: 25px;
  }

  .lg-m-25px-r {
    margin-right: 25px;
  }

  .lg-m-25px-b {
    margin-bottom: 25px;
  }

  .lg-p-25px {
    padding: 25px;
  }

  .lg-p-25px-tb {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .lg-p-25px-lr {
    padding-left: 25px;
    padding-right: 25px;
  }

  .lg-p-25px-t {
    padding-top: 25px;
  }

  .lg-p-25px-l {
    padding-left: 25px;
  }

  .lg-p-25px-r {
    padding-right: 25px;
  }

  .lg-p-25px-b {
    padding-bottom: 25px;
  }

  .lg-m-30px {
    margin: 30px;
  }

  .lg-m-30px-tb {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .lg-m-30px-lr {
    margin-left: 30px;
    margin-right: 30px;
  }

  .lg-m-30px-t {
    margin-top: 30px;
  }

  .lg-m-30px-l {
    margin-left: 30px;
  }

  .lg-m-30px-r {
    margin-right: 30px;
  }

  .lg-m-30px-b {
    margin-bottom: 30px;
  }

  .lg-p-30px {
    padding: 30px;
  }

  .lg-p-30px-tb {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lg-p-30px-lr {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg-p-30px-t {
    padding-top: 30px;
  }

  .lg-p-30px-l {
    padding-left: 30px;
  }

  .lg-p-30px-r {
    padding-right: 30px;
  }

  .lg-p-30px-b {
    padding-bottom: 30px;
  }

  .lg-m-35px {
    margin: 35px;
  }

  .lg-m-35px-tb {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .lg-m-35px-lr {
    margin-left: 35px;
    margin-right: 35px;
  }

  .lg-m-35px-t {
    margin-top: 35px;
  }

  .lg-m-35px-l {
    margin-left: 35px;
  }

  .lg-m-35px-r {
    margin-right: 35px;
  }

  .lg-m-35px-b {
    margin-bottom: 35px;
  }

  .lg-p-35px {
    padding: 35px;
  }

  .lg-p-35px-tb {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .lg-p-35px-lr {
    padding-left: 35px;
    padding-right: 35px;
  }

  .lg-p-35px-t {
    padding-top: 35px;
  }

  .lg-p-35px-l {
    padding-left: 35px;
  }

  .lg-p-35px-r {
    padding-right: 35px;
  }

  .lg-p-35px-b {
    padding-bottom: 35px;
  }

  .lg-m-40px {
    margin: 40px;
  }

  .lg-m-40px-tb {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .lg-m-40px-lr {
    margin-left: 40px;
    margin-right: 40px;
  }

  .lg-m-40px-t {
    margin-top: 40px;
  }

  .lg-m-40px-l {
    margin-left: 40px;
  }

  .lg-m-40px-r {
    margin-right: 40px;
  }

  .lg-m-40px-b {
    margin-bottom: 40px;
  }

  .lg-p-40px {
    padding: 40px;
  }

  .lg-p-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .lg-p-40px-lr {
    padding-left: 40px;
    padding-right: 40px;
  }

  .lg-p-40px-t {
    padding-top: 40px;
  }

  .lg-p-40px-l {
    padding-left: 40px;
  }

  .lg-p-40px-r {
    padding-right: 40px;
  }

  .lg-p-40px-b {
    padding-bottom: 40px;
  }

  .lg-m-45px {
    margin: 45px;
  }

  .lg-m-45px-tb {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .lg-m-45px-lr {
    margin-left: 45px;
    margin-right: 45px;
  }

  .lg-m-45px-t {
    margin-top: 45px;
  }

  .lg-m-45px-l {
    margin-left: 45px;
  }

  .lg-m-45px-r {
    margin-right: 45px;
  }

  .lg-m-45px-b {
    margin-bottom: 45px;
  }

  .lg-p-45px {
    padding: 45px;
  }

  .lg-p-45px-tb {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .lg-p-45px-lr {
    padding-left: 45px;
    padding-right: 45px;
  }

  .lg-p-45px-t {
    padding-top: 45px;
  }

  .lg-p-45px-l {
    padding-left: 45px;
  }

  .lg-p-45px-r {
    padding-right: 45px;
  }

  .lg-p-45px-b {
    padding-bottom: 45px;
  }

  .lg-m-50px {
    margin: 50px;
  }

  .lg-m-50px-tb {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .lg-m-50px-lr {
    margin-left: 50px;
    margin-right: 50px;
  }

  .lg-m-50px-t {
    margin-top: 50px;
  }

  .lg-m-50px-l {
    margin-left: 50px;
  }

  .lg-m-50px-r {
    margin-right: 50px;
  }

  .lg-m-50px-b {
    margin-bottom: 50px;
  }

  .lg-p-50px {
    padding: 50px;
  }

  .lg-p-50px-tb {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .lg-p-50px-lr {
    padding-left: 50px;
    padding-right: 50px;
  }

  .lg-p-50px-t {
    padding-top: 50px;
  }

  .lg-p-50px-l {
    padding-left: 50px;
  }

  .lg-p-50px-r {
    padding-right: 50px;
  }

  .lg-p-50px-b {
    padding-bottom: 50px;
  }

  .lg-m-55px {
    margin: 55px;
  }

  .lg-m-55px-tb {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .lg-m-55px-lr {
    margin-left: 55px;
    margin-right: 55px;
  }

  .lg-m-55px-t {
    margin-top: 55px;
  }

  .lg-m-55px-l {
    margin-left: 55px;
  }

  .lg-m-55px-r {
    margin-right: 55px;
  }

  .lg-m-55px-b {
    margin-bottom: 55px;
  }

  .lg-p-55px {
    padding: 55px;
  }

  .lg-p-55px-tb {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .lg-p-55px-lr {
    padding-left: 55px;
    padding-right: 55px;
  }

  .lg-p-55px-t {
    padding-top: 55px;
  }

  .lg-p-55px-l {
    padding-left: 55px;
  }

  .lg-p-55px-r {
    padding-right: 55px;
  }

  .lg-p-55px-b {
    padding-bottom: 55px;
  }

  .lg-m-60px {
    margin: 60px;
  }

  .lg-m-60px-tb {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .lg-m-60px-lr {
    margin-left: 60px;
    margin-right: 60px;
  }

  .lg-m-60px-t {
    margin-top: 60px;
  }

  .lg-m-60px-l {
    margin-left: 60px;
  }

  .lg-m-60px-r {
    margin-right: 60px;
  }

  .lg-m-60px-b {
    margin-bottom: 60px;
  }

  .lg-p-60px {
    padding: 60px;
  }

  .lg-p-60px-tb {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .lg-p-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
  }

  .lg-p-60px-t {
    padding-top: 60px;
  }

  .lg-p-60px-l {
    padding-left: 60px;
  }

  .lg-p-60px-r {
    padding-right: 60px;
  }

  .lg-p-60px-b {
    padding-bottom: 60px;
  }

  .lg-m-65px {
    margin: 65px;
  }

  .lg-m-65px-tb {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .lg-m-65px-lr {
    margin-left: 65px;
    margin-right: 65px;
  }

  .lg-m-65px-t {
    margin-top: 65px;
  }

  .lg-m-65px-l {
    margin-left: 65px;
  }

  .lg-m-65px-r {
    margin-right: 65px;
  }

  .lg-m-65px-b {
    margin-bottom: 65px;
  }

  .lg-p-65px {
    padding: 65px;
  }

  .lg-p-65px-tb {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .lg-p-65px-lr {
    padding-left: 65px;
    padding-right: 65px;
  }

  .lg-p-65px-t {
    padding-top: 65px;
  }

  .lg-p-65px-l {
    padding-left: 65px;
  }

  .lg-p-65px-r {
    padding-right: 65px;
  }

  .lg-p-65px-b {
    padding-bottom: 65px;
  }

  .lg-m-70px {
    margin: 70px;
  }

  .lg-m-70px-tb {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .lg-m-70px-lr {
    margin-left: 70px;
    margin-right: 70px;
  }

  .lg-m-70px-t {
    margin-top: 70px;
  }

  .lg-m-70px-l {
    margin-left: 70px;
  }

  .lg-m-70px-r {
    margin-right: 70px;
  }

  .lg-m-70px-b {
    margin-bottom: 70px;
  }

  .lg-p-70px {
    padding: 70px;
  }

  .lg-p-70px-tb {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .lg-p-70px-lr {
    padding-left: 70px;
    padding-right: 70px;
  }

  .lg-p-70px-t {
    padding-top: 70px;
  }

  .lg-p-70px-l {
    padding-left: 70px;
  }

  .lg-p-70px-r {
    padding-right: 70px;
  }

  .lg-p-70px-b {
    padding-bottom: 70px;
  }

  .lg-m-75px {
    margin: 75px;
  }

  .lg-m-75px-tb {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .lg-m-75px-lr {
    margin-left: 75px;
    margin-right: 75px;
  }

  .lg-m-75px-t {
    margin-top: 75px;
  }

  .lg-m-75px-l {
    margin-left: 75px;
  }

  .lg-m-75px-r {
    margin-right: 75px;
  }

  .lg-m-75px-b {
    margin-bottom: 75px;
  }

  .lg-p-75px {
    padding: 75px;
  }

  .lg-p-75px-tb {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .lg-p-75px-lr {
    padding-left: 75px;
    padding-right: 75px;
  }

  .lg-p-75px-t {
    padding-top: 75px;
  }

  .lg-p-75px-l {
    padding-left: 75px;
  }

  .lg-p-75px-r {
    padding-right: 75px;
  }

  .lg-p-75px-b {
    padding-bottom: 75px;
  }

  .lg-m-80px {
    margin: 80px;
  }

  .lg-m-80px-tb {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .lg-m-80px-lr {
    margin-left: 80px;
    margin-right: 80px;
  }

  .lg-m-80px-t {
    margin-top: 80px;
  }

  .lg-m-80px-l {
    margin-left: 80px;
  }

  .lg-m-80px-r {
    margin-right: 80px;
  }

  .lg-m-80px-b {
    margin-bottom: 80px;
  }

  .lg-p-80px {
    padding: 80px;
  }

  .lg-p-80px-tb {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .lg-p-80px-lr {
    padding-left: 80px;
    padding-right: 80px;
  }

  .lg-p-80px-t {
    padding-top: 80px;
  }

  .lg-p-80px-l {
    padding-left: 80px;
  }

  .lg-p-80px-r {
    padding-right: 80px;
  }

  .lg-p-80px-b {
    padding-bottom: 80px;
  }

  .lg-m-85px {
    margin: 85px;
  }

  .lg-m-85px-tb {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .lg-m-85px-lr {
    margin-left: 85px;
    margin-right: 85px;
  }

  .lg-m-85px-t {
    margin-top: 85px;
  }

  .lg-m-85px-l {
    margin-left: 85px;
  }

  .lg-m-85px-r {
    margin-right: 85px;
  }

  .lg-m-85px-b {
    margin-bottom: 85px;
  }

  .lg-p-85px {
    padding: 85px;
  }

  .lg-p-85px-tb {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .lg-p-85px-lr {
    padding-left: 85px;
    padding-right: 85px;
  }

  .lg-p-85px-t {
    padding-top: 85px;
  }

  .lg-p-85px-l {
    padding-left: 85px;
  }

  .lg-p-85px-r {
    padding-right: 85px;
  }

  .lg-p-85px-b {
    padding-bottom: 85px;
  }

  .lg-m-90px {
    margin: 90px;
  }

  .lg-m-90px-tb {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .lg-m-90px-lr {
    margin-left: 90px;
    margin-right: 90px;
  }

  .lg-m-90px-t {
    margin-top: 90px;
  }

  .lg-m-90px-l {
    margin-left: 90px;
  }

  .lg-m-90px-r {
    margin-right: 90px;
  }

  .lg-m-90px-b {
    margin-bottom: 90px;
  }

  .lg-p-90px {
    padding: 90px;
  }

  .lg-p-90px-tb {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .lg-p-90px-lr {
    padding-left: 90px;
    padding-right: 90px;
  }

  .lg-p-90px-t {
    padding-top: 90px;
  }

  .lg-p-90px-l {
    padding-left: 90px;
  }

  .lg-p-90px-r {
    padding-right: 90px;
  }

  .lg-p-90px-b {
    padding-bottom: 90px;
  }

  .lg-m-95px {
    margin: 95px;
  }

  .lg-m-95px-tb {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .lg-m-95px-lr {
    margin-left: 95px;
    margin-right: 95px;
  }

  .lg-m-95px-t {
    margin-top: 95px;
  }

  .lg-m-95px-l {
    margin-left: 95px;
  }

  .lg-m-95px-r {
    margin-right: 95px;
  }

  .lg-m-95px-b {
    margin-bottom: 95px;
  }

  .lg-p-95px {
    padding: 95px;
  }

  .lg-p-95px-tb {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .lg-p-95px-lr {
    padding-left: 95px;
    padding-right: 95px;
  }

  .lg-p-95px-t {
    padding-top: 95px;
  }

  .lg-p-95px-l {
    padding-left: 95px;
  }

  .lg-p-95px-r {
    padding-right: 95px;
  }

  .lg-p-95px-b {
    padding-bottom: 95px;
  }

  .lg-m-100px {
    margin: 100px;
  }

  .lg-m-100px-tb {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .lg-m-100px-lr {
    margin-left: 100px;
    margin-right: 100px;
  }

  .lg-m-100px-t {
    margin-top: 100px;
  }

  .lg-m-100px-l {
    margin-left: 100px;
  }

  .lg-m-100px-r {
    margin-right: 100px;
  }

  .lg-m-100px-b {
    margin-bottom: 100px;
  }

  .lg-p-100px {
    padding: 100px;
  }

  .lg-p-100px-tb {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .lg-p-100px-lr {
    padding-left: 100px;
    padding-right: 100px;
  }

  .lg-p-100px-t {
    padding-top: 100px;
  }

  .lg-p-100px-l {
    padding-left: 100px;
  }

  .lg-p-100px-r {
    padding-right: 100px;
  }

  .lg-p-100px-b {
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  /* Margin Padding
  --------------------*/
  .md-m-0px {
    margin: 0px;
  }

  .md-m-0px-tb {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md-m-0px-lr {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md-m-0px-t {
    margin-top: 0px;
  }

  .md-m-0px-l {
    margin-left: 0px;
  }

  .md-m-0px-r {
    margin-right: 0px;
  }

  .md-m-0px-b {
    margin-bottom: 0px;
  }

  .md-p-0px {
    padding: 0px;
  }

  .md-p-0px-tb {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md-p-0px-lr {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md-p-0px-t {
    padding-top: 0px;
  }

  .md-p-0px-l {
    padding-left: 0px;
  }

  .md-p-0px-r {
    padding-right: 0px;
  }

  .md-p-0px-b {
    padding-bottom: 0px;
  }

  .md-m-5px {
    margin: 5px;
  }

  .md-m-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .md-m-5px-lr {
    margin-left: 5px;
    margin-right: 5px;
  }

  .md-m-5px-t {
    margin-top: 5px;
  }

  .md-m-5px-l {
    margin-left: 5px;
  }

  .md-m-5px-r {
    margin-right: 5px;
  }

  .md-m-5px-b {
    margin-bottom: 5px;
  }

  .md-p-5px {
    padding: 5px;
  }

  .md-p-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .md-p-5px-lr {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md-p-5px-t {
    padding-top: 5px;
  }

  .md-p-5px-l {
    padding-left: 5px;
  }

  .md-p-5px-r {
    padding-right: 5px;
  }

  .md-p-5px-b {
    padding-bottom: 5px;
  }

  .md-m-10px {
    margin: 10px;
  }

  .md-m-10px-tb {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .md-m-10px-lr {
    margin-left: 10px;
    margin-right: 10px;
  }

  .md-m-10px-t {
    margin-top: 10px;
  }

  .md-m-10px-l {
    margin-left: 10px;
  }

  .md-m-10px-r {
    margin-right: 10px;
  }

  .md-m-10px-b {
    margin-bottom: 10px;
  }

  .md-p-10px {
    padding: 10px;
  }

  .md-p-10px-tb {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .md-p-10px-lr {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md-p-10px-t {
    padding-top: 10px;
  }

  .md-p-10px-l {
    padding-left: 10px;
  }

  .md-p-10px-r {
    padding-right: 10px;
  }

  .md-p-10px-b {
    padding-bottom: 10px;
  }

  .md-m-15px {
    margin: 15px;
  }

  .md-m-15px-tb {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .md-m-15px-lr {
    margin-left: 15px;
    margin-right: 15px;
  }

  .md-m-15px-t {
    margin-top: 15px;
  }

  .md-m-15px-l {
    margin-left: 15px;
  }

  .md-m-15px-r {
    margin-right: 15px;
  }

  .md-m-15px-b {
    margin-bottom: 15px;
  }

  .md-p-15px {
    padding: 15px;
  }

  .md-p-15px-tb {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .md-p-15px-lr {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md-p-15px-t {
    padding-top: 15px;
  }

  .md-p-15px-l {
    padding-left: 15px;
  }

  .md-p-15px-r {
    padding-right: 15px;
  }

  .md-p-15px-b {
    padding-bottom: 15px;
  }

  .md-m-20px {
    margin: 20px;
  }

  .md-m-20px-tb {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .md-m-20px-lr {
    margin-left: 20px;
    margin-right: 20px;
  }

  .md-m-20px-t {
    margin-top: 20px;
  }

  .md-m-20px-l {
    margin-left: 20px;
  }

  .md-m-20px-r {
    margin-right: 20px;
  }

  .md-m-20px-b {
    margin-bottom: 20px;
  }

  .md-p-20px {
    padding: 20px;
  }

  .md-p-20px-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .md-p-20px-lr {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md-p-20px-t {
    padding-top: 20px;
  }

  .md-p-20px-l {
    padding-left: 20px;
  }

  .md-p-20px-r {
    padding-right: 20px;
  }

  .md-p-20px-b {
    padding-bottom: 20px;
  }

  .md-m-25px {
    margin: 25px;
  }

  .md-m-25px-tb {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .md-m-25px-lr {
    margin-left: 25px;
    margin-right: 25px;
  }

  .md-m-25px-t {
    margin-top: 25px;
  }

  .md-m-25px-l {
    margin-left: 25px;
  }

  .md-m-25px-r {
    margin-right: 25px;
  }

  .md-m-25px-b {
    margin-bottom: 25px;
  }

  .md-p-25px {
    padding: 25px;
  }

  .md-p-25px-tb {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .md-p-25px-lr {
    padding-left: 25px;
    padding-right: 25px;
  }

  .md-p-25px-t {
    padding-top: 25px;
  }

  .md-p-25px-l {
    padding-left: 25px;
  }

  .md-p-25px-r {
    padding-right: 25px;
  }

  .md-p-25px-b {
    padding-bottom: 25px;
  }

  .md-m-30px {
    margin: 30px;
  }

  .md-m-30px-tb {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .md-m-30px-lr {
    margin-left: 30px;
    margin-right: 30px;
  }

  .md-m-30px-t {
    margin-top: 30px;
  }

  .md-m-30px-l {
    margin-left: 30px;
  }

  .md-m-30px-r {
    margin-right: 30px;
  }

  .md-m-30px-b {
    margin-bottom: 30px;
  }

  .md-p-30px {
    padding: 30px;
  }

  .md-p-30px-tb {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md-p-30px-lr {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md-p-30px-t {
    padding-top: 30px;
  }

  .md-p-30px-l {
    padding-left: 30px;
  }

  .md-p-30px-r {
    padding-right: 30px;
  }

  .md-p-30px-b {
    padding-bottom: 30px;
  }

  .md-m-35px {
    margin: 35px;
  }

  .md-m-35px-tb {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .md-m-35px-lr {
    margin-left: 35px;
    margin-right: 35px;
  }

  .md-m-35px-t {
    margin-top: 35px;
  }

  .md-m-35px-l {
    margin-left: 35px;
  }

  .md-m-35px-r {
    margin-right: 35px;
  }

  .md-m-35px-b {
    margin-bottom: 35px;
  }

  .md-p-35px {
    padding: 35px;
  }

  .md-p-35px-tb {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .md-p-35px-lr {
    padding-left: 35px;
    padding-right: 35px;
  }

  .md-p-35px-t {
    padding-top: 35px;
  }

  .md-p-35px-l {
    padding-left: 35px;
  }

  .md-p-35px-r {
    padding-right: 35px;
  }

  .md-p-35px-b {
    padding-bottom: 35px;
  }

  .md-m-40px {
    margin: 40px;
  }

  .md-m-40px-tb {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .md-m-40px-lr {
    margin-left: 40px;
    margin-right: 40px;
  }

  .md-m-40px-t {
    margin-top: 40px;
  }

  .md-m-40px-l {
    margin-left: 40px;
  }

  .md-m-40px-r {
    margin-right: 40px;
  }

  .md-m-40px-b {
    margin-bottom: 40px;
  }

  .md-p-40px {
    padding: 40px;
  }

  .md-p-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md-p-40px-lr {
    padding-left: 40px;
    padding-right: 40px;
  }

  .md-p-40px-t {
    padding-top: 40px;
  }

  .md-p-40px-l {
    padding-left: 40px;
  }

  .md-p-40px-r {
    padding-right: 40px;
  }

  .md-p-40px-b {
    padding-bottom: 40px;
  }

  .md-m-45px {
    margin: 45px;
  }

  .md-m-45px-tb {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .md-m-45px-lr {
    margin-left: 45px;
    margin-right: 45px;
  }

  .md-m-45px-t {
    margin-top: 45px;
  }

  .md-m-45px-l {
    margin-left: 45px;
  }

  .md-m-45px-r {
    margin-right: 45px;
  }

  .md-m-45px-b {
    margin-bottom: 45px;
  }

  .md-p-45px {
    padding: 45px;
  }

  .md-p-45px-tb {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .md-p-45px-lr {
    padding-left: 45px;
    padding-right: 45px;
  }

  .md-p-45px-t {
    padding-top: 45px;
  }

  .md-p-45px-l {
    padding-left: 45px;
  }

  .md-p-45px-r {
    padding-right: 45px;
  }

  .md-p-45px-b {
    padding-bottom: 45px;
  }

  .md-m-50px {
    margin: 50px;
  }

  .md-m-50px-tb {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .md-m-50px-lr {
    margin-left: 50px;
    margin-right: 50px;
  }

  .md-m-50px-t {
    margin-top: 50px;
  }

  .md-m-50px-l {
    margin-left: 50px;
  }

  .md-m-50px-r {
    margin-right: 50px;
  }

  .md-m-50px-b {
    margin-bottom: 50px;
  }

  .md-p-50px {
    padding: 50px;
  }

  .md-p-50px-tb {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md-p-50px-lr {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md-p-50px-t {
    padding-top: 50px;
  }

  .md-p-50px-l {
    padding-left: 50px;
  }

  .md-p-50px-r {
    padding-right: 50px;
  }

  .md-p-50px-b {
    padding-bottom: 50px;
  }

  .md-m-55px {
    margin: 55px;
  }

  .md-m-55px-tb {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .md-m-55px-lr {
    margin-left: 55px;
    margin-right: 55px;
  }

  .md-m-55px-t {
    margin-top: 55px;
  }

  .md-m-55px-l {
    margin-left: 55px;
  }

  .md-m-55px-r {
    margin-right: 55px;
  }

  .md-m-55px-b {
    margin-bottom: 55px;
  }

  .md-p-55px {
    padding: 55px;
  }

  .md-p-55px-tb {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .md-p-55px-lr {
    padding-left: 55px;
    padding-right: 55px;
  }

  .md-p-55px-t {
    padding-top: 55px;
  }

  .md-p-55px-l {
    padding-left: 55px;
  }

  .md-p-55px-r {
    padding-right: 55px;
  }

  .md-p-55px-b {
    padding-bottom: 55px;
  }

  .md-m-60px {
    margin: 60px;
  }

  .md-m-60px-tb {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .md-m-60px-lr {
    margin-left: 60px;
    margin-right: 60px;
  }

  .md-m-60px-t {
    margin-top: 60px;
  }

  .md-m-60px-l {
    margin-left: 60px;
  }

  .md-m-60px-r {
    margin-right: 60px;
  }

  .md-m-60px-b {
    margin-bottom: 60px;
  }

  .md-p-60px {
    padding: 60px;
  }

  .md-p-60px-tb {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .md-p-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
  }

  .md-p-60px-t {
    padding-top: 60px;
  }

  .md-p-60px-l {
    padding-left: 60px;
  }

  .md-p-60px-r {
    padding-right: 60px;
  }

  .md-p-60px-b {
    padding-bottom: 60px;
  }

  .md-m-65px {
    margin: 65px;
  }

  .md-m-65px-tb {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .md-m-65px-lr {
    margin-left: 65px;
    margin-right: 65px;
  }

  .md-m-65px-t {
    margin-top: 65px;
  }

  .md-m-65px-l {
    margin-left: 65px;
  }

  .md-m-65px-r {
    margin-right: 65px;
  }

  .md-m-65px-b {
    margin-bottom: 65px;
  }

  .md-p-65px {
    padding: 65px;
  }

  .md-p-65px-tb {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .md-p-65px-lr {
    padding-left: 65px;
    padding-right: 65px;
  }

  .md-p-65px-t {
    padding-top: 65px;
  }

  .md-p-65px-l {
    padding-left: 65px;
  }

  .md-p-65px-r {
    padding-right: 65px;
  }

  .md-p-65px-b {
    padding-bottom: 65px;
  }

  .md-m-70px {
    margin: 70px;
  }

  .md-m-70px-tb {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .md-m-70px-lr {
    margin-left: 70px;
    margin-right: 70px;
  }

  .md-m-70px-t {
    margin-top: 70px;
  }

  .md-m-70px-l {
    margin-left: 70px;
  }

  .md-m-70px-r {
    margin-right: 70px;
  }

  .md-m-70px-b {
    margin-bottom: 70px;
  }

  .md-p-70px {
    padding: 70px;
  }

  .md-p-70px-tb {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .md-p-70px-lr {
    padding-left: 70px;
    padding-right: 70px;
  }

  .md-p-70px-t {
    padding-top: 70px;
  }

  .md-p-70px-l {
    padding-left: 70px;
  }

  .md-p-70px-r {
    padding-right: 70px;
  }

  .md-p-70px-b {
    padding-bottom: 70px;
  }

  .md-m-75px {
    margin: 75px;
  }

  .md-m-75px-tb {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .md-m-75px-lr {
    margin-left: 75px;
    margin-right: 75px;
  }

  .md-m-75px-t {
    margin-top: 75px;
  }

  .md-m-75px-l {
    margin-left: 75px;
  }

  .md-m-75px-r {
    margin-right: 75px;
  }

  .md-m-75px-b {
    margin-bottom: 75px;
  }

  .md-p-75px {
    padding: 75px;
  }

  .md-p-75px-tb {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .md-p-75px-lr {
    padding-left: 75px;
    padding-right: 75px;
  }

  .md-p-75px-t {
    padding-top: 75px;
  }

  .md-p-75px-l {
    padding-left: 75px;
  }

  .md-p-75px-r {
    padding-right: 75px;
  }

  .md-p-75px-b {
    padding-bottom: 75px;
  }

  .md-m-80px {
    margin: 80px;
  }

  .md-m-80px-tb {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .md-m-80px-lr {
    margin-left: 80px;
    margin-right: 80px;
  }

  .md-m-80px-t {
    margin-top: 80px;
  }

  .md-m-80px-l {
    margin-left: 80px;
  }

  .md-m-80px-r {
    margin-right: 80px;
  }

  .md-m-80px-b {
    margin-bottom: 80px;
  }

  .md-p-80px {
    padding: 80px;
  }

  .md-p-80px-tb {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .md-p-80px-lr {
    padding-left: 80px;
    padding-right: 80px;
  }

  .md-p-80px-t {
    padding-top: 80px;
  }

  .md-p-80px-l {
    padding-left: 80px;
  }

  .md-p-80px-r {
    padding-right: 80px;
  }

  .md-p-80px-b {
    padding-bottom: 80px;
  }

  .md-m-85px {
    margin: 85px;
  }

  .md-m-85px-tb {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .md-m-85px-lr {
    margin-left: 85px;
    margin-right: 85px;
  }

  .md-m-85px-t {
    margin-top: 85px;
  }

  .md-m-85px-l {
    margin-left: 85px;
  }

  .md-m-85px-r {
    margin-right: 85px;
  }

  .md-m-85px-b {
    margin-bottom: 85px;
  }

  .md-p-85px {
    padding: 85px;
  }

  .md-p-85px-tb {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .md-p-85px-lr {
    padding-left: 85px;
    padding-right: 85px;
  }

  .md-p-85px-t {
    padding-top: 85px;
  }

  .md-p-85px-l {
    padding-left: 85px;
  }

  .md-p-85px-r {
    padding-right: 85px;
  }

  .md-p-85px-b {
    padding-bottom: 85px;
  }

  .md-m-90px {
    margin: 90px;
  }

  .md-m-90px-tb {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .md-m-90px-lr {
    margin-left: 90px;
    margin-right: 90px;
  }

  .md-m-90px-t {
    margin-top: 90px;
  }

  .md-m-90px-l {
    margin-left: 90px;
  }

  .md-m-90px-r {
    margin-right: 90px;
  }

  .md-m-90px-b {
    margin-bottom: 90px;
  }

  .md-p-90px {
    padding: 90px;
  }

  .md-p-90px-tb {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .md-p-90px-lr {
    padding-left: 90px;
    padding-right: 90px;
  }

  .md-p-90px-t {
    padding-top: 90px;
  }

  .md-p-90px-l {
    padding-left: 90px;
  }

  .md-p-90px-r {
    padding-right: 90px;
  }

  .md-p-90px-b {
    padding-bottom: 90px;
  }

  .md-m-95px {
    margin: 95px;
  }

  .md-m-95px-tb {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .md-m-95px-lr {
    margin-left: 95px;
    margin-right: 95px;
  }

  .md-m-95px-t {
    margin-top: 95px;
  }

  .md-m-95px-l {
    margin-left: 95px;
  }

  .md-m-95px-r {
    margin-right: 95px;
  }

  .md-m-95px-b {
    margin-bottom: 95px;
  }

  .md-p-95px {
    padding: 95px;
  }

  .md-p-95px-tb {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .md-p-95px-lr {
    padding-left: 95px;
    padding-right: 95px;
  }

  .md-p-95px-t {
    padding-top: 95px;
  }

  .md-p-95px-l {
    padding-left: 95px;
  }

  .md-p-95px-r {
    padding-right: 95px;
  }

  .md-p-95px-b {
    padding-bottom: 95px;
  }

  .md-m-100px {
    margin: 100px;
  }

  .md-m-100px-tb {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .md-m-100px-lr {
    margin-left: 100px;
    margin-right: 100px;
  }

  .md-m-100px-t {
    margin-top: 100px;
  }

  .md-m-100px-l {
    margin-left: 100px;
  }

  .md-m-100px-r {
    margin-right: 100px;
  }

  .md-m-100px-b {
    margin-bottom: 100px;
  }

  .md-p-100px {
    padding: 100px;
  }

  .md-p-100px-tb {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .md-p-100px-lr {
    padding-left: 100px;
    padding-right: 100px;
  }

  .md-p-100px-t {
    padding-top: 100px;
  }

  .md-p-100px-l {
    padding-left: 100px;
  }

  .md-p-100px-r {
    padding-right: 100px;
  }

  .md-p-100px-b {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  /* Margin Padding
  --------------------*/
  .sm-m-0px {
    margin: 0px;
  }

  .sm-m-0px-tb {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm-m-0px-lr {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm-m-0px-t {
    margin-top: 0px;
  }

  .sm-m-0px-l {
    margin-left: 0px;
  }

  .sm-m-0px-r {
    margin-right: 0px;
  }

  .sm-m-0px-b {
    margin-bottom: 0px;
  }

  .sm-p-0px {
    padding: 0px;
  }

  .sm-p-0px-tb {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm-p-0px-lr {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm-p-0px-t {
    padding-top: 0px;
  }

  .sm-p-0px-l {
    padding-left: 0px;
  }

  .sm-p-0px-r {
    padding-right: 0px;
  }

  .sm-p-0px-b {
    padding-bottom: 0px;
  }

  .sm-m-5px {
    margin: 5px;
  }

  .sm-m-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sm-m-5px-lr {
    margin-left: 5px;
    margin-right: 5px;
  }

  .sm-m-5px-t {
    margin-top: 5px;
  }

  .sm-m-5px-l {
    margin-left: 5px;
  }

  .sm-m-5px-r {
    margin-right: 5px;
  }

  .sm-m-5px-b {
    margin-bottom: 5px;
  }

  .sm-p-5px {
    padding: 5px;
  }

  .sm-p-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sm-p-5px-lr {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sm-p-5px-t {
    padding-top: 5px;
  }

  .sm-p-5px-l {
    padding-left: 5px;
  }

  .sm-p-5px-r {
    padding-right: 5px;
  }

  .sm-p-5px-b {
    padding-bottom: 5px;
  }

  .sm-m-10px {
    margin: 10px;
  }

  .sm-m-10px-tb {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .sm-m-10px-lr {
    margin-left: 10px;
    margin-right: 10px;
  }

  .sm-m-10px-t {
    margin-top: 10px;
  }

  .sm-m-10px-l {
    margin-left: 10px;
  }

  .sm-m-10px-r {
    margin-right: 10px;
  }

  .sm-m-10px-b {
    margin-bottom: 10px;
  }

  .sm-p-10px {
    padding: 10px;
  }

  .sm-p-10px-tb {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sm-p-10px-lr {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm-p-10px-t {
    padding-top: 10px;
  }

  .sm-p-10px-l {
    padding-left: 10px;
  }

  .sm-p-10px-r {
    padding-right: 10px;
  }

  .sm-p-10px-b {
    padding-bottom: 10px;
  }

  .sm-m-15px {
    margin: 15px;
  }

  .sm-m-15px-tb {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .sm-m-15px-lr {
    margin-left: 15px;
    margin-right: 15px;
  }

  .sm-m-15px-t {
    margin-top: 15px;
  }

  .sm-m-15px-l {
    margin-left: 15px;
  }

  .sm-m-15px-r {
    margin-right: 15px;
  }

  .sm-m-15px-b {
    margin-bottom: 15px;
  }

  .sm-p-15px {
    padding: 15px;
  }

  .sm-p-15px-tb {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sm-p-15px-lr {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sm-p-15px-t {
    padding-top: 15px;
  }

  .sm-p-15px-l {
    padding-left: 15px;
  }

  .sm-p-15px-r {
    padding-right: 15px;
  }

  .sm-p-15px-b {
    padding-bottom: 15px;
  }

  .sm-m-20px {
    margin: 20px;
  }

  .sm-m-20px-tb {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sm-m-20px-lr {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sm-m-20px-t {
    margin-top: 20px;
  }

  .sm-m-20px-l {
    margin-left: 20px;
  }

  .sm-m-20px-r {
    margin-right: 20px;
  }

  .sm-m-20px-b {
    margin-bottom: 20px;
  }

  .sm-p-20px {
    padding: 20px;
  }

  .sm-p-20px-tb {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sm-p-20px-lr {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm-p-20px-t {
    padding-top: 20px;
  }

  .sm-p-20px-l {
    padding-left: 20px;
  }

  .sm-p-20px-r {
    padding-right: 20px;
  }

  .sm-p-20px-b {
    padding-bottom: 20px;
  }

  .sm-m-25px {
    margin: 25px;
  }

  .sm-m-25px-tb {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .sm-m-25px-lr {
    margin-left: 25px;
    margin-right: 25px;
  }

  .sm-m-25px-t {
    margin-top: 25px;
  }

  .sm-m-25px-l {
    margin-left: 25px;
  }

  .sm-m-25px-r {
    margin-right: 25px;
  }

  .sm-m-25px-b {
    margin-bottom: 25px;
  }

  .sm-p-25px {
    padding: 25px;
  }

  .sm-p-25px-tb {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .sm-p-25px-lr {
    padding-left: 25px;
    padding-right: 25px;
  }

  .sm-p-25px-t {
    padding-top: 25px;
  }

  .sm-p-25px-l {
    padding-left: 25px;
  }

  .sm-p-25px-r {
    padding-right: 25px;
  }

  .sm-p-25px-b {
    padding-bottom: 25px;
  }

  .sm-m-30px {
    margin: 30px;
  }

  .sm-m-30px-tb {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sm-m-30px-lr {
    margin-left: 30px;
    margin-right: 30px;
  }

  .sm-m-30px-t {
    margin-top: 30px;
  }

  .sm-m-30px-l {
    margin-left: 30px;
  }

  .sm-m-30px-r {
    margin-right: 30px;
  }

  .sm-m-30px-b {
    margin-bottom: 30px;
  }

  .sm-p-30px {
    padding: 30px;
  }

  .sm-p-30px-tb {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm-p-30px-lr {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm-p-30px-t {
    padding-top: 30px;
  }

  .sm-p-30px-l {
    padding-left: 30px;
  }

  .sm-p-30px-r {
    padding-right: 30px;
  }

  .sm-p-30px-b {
    padding-bottom: 30px;
  }

  .sm-m-35px {
    margin: 35px;
  }

  .sm-m-35px-tb {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .sm-m-35px-lr {
    margin-left: 35px;
    margin-right: 35px;
  }

  .sm-m-35px-t {
    margin-top: 35px;
  }

  .sm-m-35px-l {
    margin-left: 35px;
  }

  .sm-m-35px-r {
    margin-right: 35px;
  }

  .sm-m-35px-b {
    margin-bottom: 35px;
  }

  .sm-p-35px {
    padding: 35px;
  }

  .sm-p-35px-tb {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .sm-p-35px-lr {
    padding-left: 35px;
    padding-right: 35px;
  }

  .sm-p-35px-t {
    padding-top: 35px;
  }

  .sm-p-35px-l {
    padding-left: 35px;
  }

  .sm-p-35px-r {
    padding-right: 35px;
  }

  .sm-p-35px-b {
    padding-bottom: 35px;
  }

  .sm-m-40px {
    margin: 40px;
  }

  .sm-m-40px-tb {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sm-m-40px-lr {
    margin-left: 40px;
    margin-right: 40px;
  }

  .sm-m-40px-t {
    margin-top: 40px;
  }

  .sm-m-40px-l {
    margin-left: 40px;
  }

  .sm-m-40px-r {
    margin-right: 40px;
  }

  .sm-m-40px-b {
    margin-bottom: 40px;
  }

  .sm-p-40px {
    padding: 40px;
  }

  .sm-p-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sm-p-40px-lr {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sm-p-40px-t {
    padding-top: 40px;
  }

  .sm-p-40px-l {
    padding-left: 40px;
  }

  .sm-p-40px-r {
    padding-right: 40px;
  }

  .sm-p-40px-b {
    padding-bottom: 40px;
  }

  .sm-m-45px {
    margin: 45px;
  }

  .sm-m-45px-tb {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .sm-m-45px-lr {
    margin-left: 45px;
    margin-right: 45px;
  }

  .sm-m-45px-t {
    margin-top: 45px;
  }

  .sm-m-45px-l {
    margin-left: 45px;
  }

  .sm-m-45px-r {
    margin-right: 45px;
  }

  .sm-m-45px-b {
    margin-bottom: 45px;
  }

  .sm-p-45px {
    padding: 45px;
  }

  .sm-p-45px-tb {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .sm-p-45px-lr {
    padding-left: 45px;
    padding-right: 45px;
  }

  .sm-p-45px-t {
    padding-top: 45px;
  }

  .sm-p-45px-l {
    padding-left: 45px;
  }

  .sm-p-45px-r {
    padding-right: 45px;
  }

  .sm-p-45px-b {
    padding-bottom: 45px;
  }

  .sm-m-50px {
    margin: 50px;
  }

  .sm-m-50px-tb {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .sm-m-50px-lr {
    margin-left: 50px;
    margin-right: 50px;
  }

  .sm-m-50px-t {
    margin-top: 50px;
  }

  .sm-m-50px-l {
    margin-left: 50px;
  }

  .sm-m-50px-r {
    margin-right: 50px;
  }

  .sm-m-50px-b {
    margin-bottom: 50px;
  }

  .sm-p-50px {
    padding: 50px;
  }

  .sm-p-50px-tb {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sm-p-50px-lr {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sm-p-50px-t {
    padding-top: 50px;
  }

  .sm-p-50px-l {
    padding-left: 50px;
  }

  .sm-p-50px-r {
    padding-right: 50px;
  }

  .sm-p-50px-b {
    padding-bottom: 50px;
  }

  .sm-m-55px {
    margin: 55px;
  }

  .sm-m-55px-tb {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .sm-m-55px-lr {
    margin-left: 55px;
    margin-right: 55px;
  }

  .sm-m-55px-t {
    margin-top: 55px;
  }

  .sm-m-55px-l {
    margin-left: 55px;
  }

  .sm-m-55px-r {
    margin-right: 55px;
  }

  .sm-m-55px-b {
    margin-bottom: 55px;
  }

  .sm-p-55px {
    padding: 55px;
  }

  .sm-p-55px-tb {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .sm-p-55px-lr {
    padding-left: 55px;
    padding-right: 55px;
  }

  .sm-p-55px-t {
    padding-top: 55px;
  }

  .sm-p-55px-l {
    padding-left: 55px;
  }

  .sm-p-55px-r {
    padding-right: 55px;
  }

  .sm-p-55px-b {
    padding-bottom: 55px;
  }

  .sm-m-60px {
    margin: 60px;
  }

  .sm-m-60px-tb {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .sm-m-60px-lr {
    margin-left: 60px;
    margin-right: 60px;
  }

  .sm-m-60px-t {
    margin-top: 60px;
  }

  .sm-m-60px-l {
    margin-left: 60px;
  }

  .sm-m-60px-r {
    margin-right: 60px;
  }

  .sm-m-60px-b {
    margin-bottom: 60px;
  }

  .sm-p-60px {
    padding: 60px;
  }

  .sm-p-60px-tb {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sm-p-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sm-p-60px-t {
    padding-top: 60px;
  }

  .sm-p-60px-l {
    padding-left: 60px;
  }

  .sm-p-60px-r {
    padding-right: 60px;
  }

  .sm-p-60px-b {
    padding-bottom: 60px;
  }

  .sm-m-65px {
    margin: 65px;
  }

  .sm-m-65px-tb {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .sm-m-65px-lr {
    margin-left: 65px;
    margin-right: 65px;
  }

  .sm-m-65px-t {
    margin-top: 65px;
  }

  .sm-m-65px-l {
    margin-left: 65px;
  }

  .sm-m-65px-r {
    margin-right: 65px;
  }

  .sm-m-65px-b {
    margin-bottom: 65px;
  }

  .sm-p-65px {
    padding: 65px;
  }

  .sm-p-65px-tb {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .sm-p-65px-lr {
    padding-left: 65px;
    padding-right: 65px;
  }

  .sm-p-65px-t {
    padding-top: 65px;
  }

  .sm-p-65px-l {
    padding-left: 65px;
  }

  .sm-p-65px-r {
    padding-right: 65px;
  }

  .sm-p-65px-b {
    padding-bottom: 65px;
  }

  .sm-m-70px {
    margin: 70px;
  }

  .sm-m-70px-tb {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .sm-m-70px-lr {
    margin-left: 70px;
    margin-right: 70px;
  }

  .sm-m-70px-t {
    margin-top: 70px;
  }

  .sm-m-70px-l {
    margin-left: 70px;
  }

  .sm-m-70px-r {
    margin-right: 70px;
  }

  .sm-m-70px-b {
    margin-bottom: 70px;
  }

  .sm-p-70px {
    padding: 70px;
  }

  .sm-p-70px-tb {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sm-p-70px-lr {
    padding-left: 70px;
    padding-right: 70px;
  }

  .sm-p-70px-t {
    padding-top: 70px;
  }

  .sm-p-70px-l {
    padding-left: 70px;
  }

  .sm-p-70px-r {
    padding-right: 70px;
  }

  .sm-p-70px-b {
    padding-bottom: 70px;
  }

  .sm-m-75px {
    margin: 75px;
  }

  .sm-m-75px-tb {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .sm-m-75px-lr {
    margin-left: 75px;
    margin-right: 75px;
  }

  .sm-m-75px-t {
    margin-top: 75px;
  }

  .sm-m-75px-l {
    margin-left: 75px;
  }

  .sm-m-75px-r {
    margin-right: 75px;
  }

  .sm-m-75px-b {
    margin-bottom: 75px;
  }

  .sm-p-75px {
    padding: 75px;
  }

  .sm-p-75px-tb {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .sm-p-75px-lr {
    padding-left: 75px;
    padding-right: 75px;
  }

  .sm-p-75px-t {
    padding-top: 75px;
  }

  .sm-p-75px-l {
    padding-left: 75px;
  }

  .sm-p-75px-r {
    padding-right: 75px;
  }

  .sm-p-75px-b {
    padding-bottom: 75px;
  }

  .sm-m-80px {
    margin: 80px;
  }

  .sm-m-80px-tb {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .sm-m-80px-lr {
    margin-left: 80px;
    margin-right: 80px;
  }

  .sm-m-80px-t {
    margin-top: 80px;
  }

  .sm-m-80px-l {
    margin-left: 80px;
  }

  .sm-m-80px-r {
    margin-right: 80px;
  }

  .sm-m-80px-b {
    margin-bottom: 80px;
  }

  .sm-p-80px {
    padding: 80px;
  }

  .sm-p-80px-tb {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sm-p-80px-lr {
    padding-left: 80px;
    padding-right: 80px;
  }

  .sm-p-80px-t {
    padding-top: 80px;
  }

  .sm-p-80px-l {
    padding-left: 80px;
  }

  .sm-p-80px-r {
    padding-right: 80px;
  }

  .sm-p-80px-b {
    padding-bottom: 80px;
  }

  .sm-m-85px {
    margin: 85px;
  }

  .sm-m-85px-tb {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .sm-m-85px-lr {
    margin-left: 85px;
    margin-right: 85px;
  }

  .sm-m-85px-t {
    margin-top: 85px;
  }

  .sm-m-85px-l {
    margin-left: 85px;
  }

  .sm-m-85px-r {
    margin-right: 85px;
  }

  .sm-m-85px-b {
    margin-bottom: 85px;
  }

  .sm-p-85px {
    padding: 85px;
  }

  .sm-p-85px-tb {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .sm-p-85px-lr {
    padding-left: 85px;
    padding-right: 85px;
  }

  .sm-p-85px-t {
    padding-top: 85px;
  }

  .sm-p-85px-l {
    padding-left: 85px;
  }

  .sm-p-85px-r {
    padding-right: 85px;
  }

  .sm-p-85px-b {
    padding-bottom: 85px;
  }

  .sm-m-90px {
    margin: 90px;
  }

  .sm-m-90px-tb {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .sm-m-90px-lr {
    margin-left: 90px;
    margin-right: 90px;
  }

  .sm-m-90px-t {
    margin-top: 90px;
  }

  .sm-m-90px-l {
    margin-left: 90px;
  }

  .sm-m-90px-r {
    margin-right: 90px;
  }

  .sm-m-90px-b {
    margin-bottom: 90px;
  }

  .sm-p-90px {
    padding: 90px;
  }

  .sm-p-90px-tb {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .sm-p-90px-lr {
    padding-left: 90px;
    padding-right: 90px;
  }

  .sm-p-90px-t {
    padding-top: 90px;
  }

  .sm-p-90px-l {
    padding-left: 90px;
  }

  .sm-p-90px-r {
    padding-right: 90px;
  }

  .sm-p-90px-b {
    padding-bottom: 90px;
  }

  .sm-m-95px {
    margin: 95px;
  }

  .sm-m-95px-tb {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .sm-m-95px-lr {
    margin-left: 95px;
    margin-right: 95px;
  }

  .sm-m-95px-t {
    margin-top: 95px;
  }

  .sm-m-95px-l {
    margin-left: 95px;
  }

  .sm-m-95px-r {
    margin-right: 95px;
  }

  .sm-m-95px-b {
    margin-bottom: 95px;
  }

  .sm-p-95px {
    padding: 95px;
  }

  .sm-p-95px-tb {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .sm-p-95px-lr {
    padding-left: 95px;
    padding-right: 95px;
  }

  .sm-p-95px-t {
    padding-top: 95px;
  }

  .sm-p-95px-l {
    padding-left: 95px;
  }

  .sm-p-95px-r {
    padding-right: 95px;
  }

  .sm-p-95px-b {
    padding-bottom: 95px;
  }

  .sm-m-100px {
    margin: 100px;
  }

  .sm-m-100px-tb {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .sm-m-100px-lr {
    margin-left: 100px;
    margin-right: 100px;
  }

  .sm-m-100px-t {
    margin-top: 100px;
  }

  .sm-m-100px-l {
    margin-left: 100px;
  }

  .sm-m-100px-r {
    margin-right: 100px;
  }

  .sm-m-100px-b {
    margin-bottom: 100px;
  }

  .sm-p-100px {
    padding: 100px;
  }

  .sm-p-100px-tb {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .sm-p-100px-lr {
    padding-left: 100px;
    padding-right: 100px;
  }

  .sm-p-100px-t {
    padding-top: 100px;
  }

  .sm-p-100px-l {
    padding-left: 100px;
  }

  .sm-p-100px-r {
    padding-right: 100px;
  }

  .sm-p-100px-b {
    padding-bottom: 100px;
  }
}
/* Wrapper
---------------------------------*/
.wrapper-right {
  padding-left: 200px;
  background: #ffffff;
}
.wrapper-right .section {
  position: relative;
  z-index: 1;
}

.jquery-ripples canvas {
  min-width: 100vw;
  min-height: 100vh;
}

.particles-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
}

/* Home Banner
---------------------------------*/
.home-banner-01 h3 {
  font-size: 55px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .home-banner-01 h3 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .home-banner-01 h3 {
    font-size: 30px;
  }
}
.home-banner-01 h2 {
  font-size: 90px;
  letter-spacing: 2px;
}
@media (max-width: 991px) {
  .home-banner-01 h2 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .home-banner-01 h2 {
    font-size: 40px;
  }
}
.home-banner-01 p {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
}

.home-banner-02 h2 {
  font-size: 60px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .home-banner-02 h2 {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .home-banner-02 h2 {
    font-size: 40px;
  }
}
.home-banner-02 p {
  margin: 0;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
}
@media (max-width: 991px) {
  .home-banner-02 p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .home-banner-02 p {
    font-size: 16px;
  }
}

.home-banner-03 .ht-img {
  width: 200px;
  display: inline-block;
  vertical-align: top;
}
.home-banner-03 h2 {
  font-size: 60px;
  letter-spacing: 2px;
}
@media (max-width: 991px) {
  .home-banner-03 h2 {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .home-banner-03 h2 {
    font-size: 40px;
  }
}
.home-banner-03 p {
  margin: 0;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
}
@media (max-width: 991px) {
  .home-banner-03 p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .home-banner-03 p {
    font-size: 16px;
  }
}

/* Feature
---------------------------------*/
.feature-box-1 {
  background: #ffffff;
  border-radius: 5px;
  border: 5px solid transparent;
}
.feature-box-1 .f-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  display: inline-block;
  font-size: 54px;
}
.feature-box-1:hover {
  border-color: #f34236;
}

.feature-box-2 {
  text-align: center;
}
.feature-box-2 .icon {
  background: #ffffff;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  color: #f34236;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 18px;
  position: relative;
}
.feature-box-2 .icon span {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #f34236;
  border-radius: 50%;
  line-height: 34px;
  font-size: 17px;
  color: #ffffff;
  border: 2px solid #ffffff;
}
.feature-box-2 h6 {
  margin: 0;
  font-size: 15px;
  color: #111111;
}

.feature-box-3 .icon span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
}
.feature-box-3 .feature-content {
  padding-left: 15px;
}
.feature-box-3 .feature-content h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}
.feature-box-3 .feature-content p {
  margin: 0;
}

.feature-box-4 {
  background: #ffffff;
  border-radius: 5px;
  border-bottom: 5px solid transparent;
}
.feature-box-4 .f-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  display: inline-block;
  font-size: 54px;
}
.feature-box-4:hover {
  border-color: #f34236;
}

/* Testimonials
--------------------------------*/
.testimonial-col-01 {
  padding: 50px 20px 30px;
  border: 1px solid #ddd;
  margin: 15px 10px 30px 10px;
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
}
.testimonial-col-01 .tc-info {
  margin: 20px 0 0;
}
.testimonial-col-01 .tc-info h5 {
  margin: 0;
  padding: 0 0 4px;
  font-size: 18px;
  font-weight: 600;
  color: #111111;
}
.testimonial-col-01 .tc-info label {
  font-weight: normal;
  margin: 0;
}
.testimonial-col-01 p {
  margin: 0;
  font-size: 16px;
}
.testimonial-col-01 .img {
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 0 20px;
}

/* Portfolio
--------------------------------*/
.portfolio-content.grid-col-2 .grid-item {
  width: 50%;
}
@media (max-width: 567px) {
  .portfolio-content.grid-col-2 .grid-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 33.3333%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 50%;
  }
}
@media (max-width: 567px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 33.3333%;
  }
}
@media (min-width: 992px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 50%;
  }
}
@media (max-width: 567px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 33.3333%;
  }
}
@media (min-width: 992px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 50%;
  }
}
@media (max-width: 567px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 100%;
  }
}
.portfolio-content.grid-gutter-md {
  margin-left: -8px;
  margin-right: -8px;
}
.portfolio-content.grid-gutter-md .grid-item {
  padding: 8px;
}
.portfolio-content.grid-gutter-lg {
  margin-left: -12px;
  margin-right: -12px;
}
.portfolio-content.grid-gutter-lg .grid-item {
  padding: 12px;
}
.portfolio-content .grid-item {
  float: left;
}

/*Galletry*/
/* .gallery-box {
  position: relative;
  overflow: hidden;
}
.gallery-box .gallery-info {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
  opacity: 0;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.gallery-box .gallery-info a {
  position: absolute;
  bottom: 13px;
  left: 16px;
  color: #111111;
}
.gallery-box:hover .gallery-info {
  opacity: 1;
} */

/*Portfolio Filter*/
.portfolio-filter-01 .filter li {
  cursor: pointer;
  margin: 0 15px;
  color: #111111;
  position: relative;
  padding: 5px 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
}
@media (max-width: 767px) {
  .portfolio-filter-01 .filter li {
    font-size: 12px;
    margin: 0 7px;
  }
}
.portfolio-filter-01 .filter li:after {
  content: "";
  width: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #f34236;
  position: absolute;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.portfolio-filter-01 .filter li:last-child {
  margin-right: 0;
}
.portfolio-filter-01 .filter li:first-child {
  margin-left: 0;
}
.portfolio-filter-01 .filter li:hover {
  color: #f34236;
}
.portfolio-filter-01 .filter li.active {
  color: #f34236;
}
.portfolio-filter-01 .filter li.active:after {
  width: 100%;
}

/*Portfolio Style 1*/
.portfolio-box-01 {
  position: relative;
  overflow: hidden;
}
.portfolio-box-01 .portfolio-info {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: rgba(17, 17, 17, 0.4);
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  opacity: 0;
}
.portfolio-box-01 .gallery-link {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.portfolio-box-01 .gallery-link i {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -moz-transition: ease-in-out all 0.35s;
  -o-transition: ease-in-out all 0.35s;
  -webkit-transition: ease-in-out all 0.35s;
  transition: ease-in-out all 0.35s;
  width: 0px;
  height: 0px;
}
.portfolio-box-01 .gallery-link i:after,
.portfolio-box-01 .gallery-link i:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
}
.portfolio-box-01 .gallery-link i:after {
  width: 1px;
  height: 100%;
}
.portfolio-box-01 .gallery-link i:before {
  height: 1px;
  width: 100%;
}
.portfolio-box-01 h5 {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f34236;
  font-size: 16px;
  padding: 20px;
  margin: 0;
  font-weight: 500;
  text-align: center;
}
.portfolio-box-01 h5 a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
}
.portfolio-box-01:hover .portfolio-info {
  position: absolute;
  opacity: 1;
}
.portfolio-box-01:hover .gallery-link i {
  width: 100%;
  height: 100%;
}

/* Price table
--------------------*/
/* .price-table h5 {
  margin: 0 0 26px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  padding: 6px 22px;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.price-table .pt-top {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  text-align: center;
  background: #f34236;
}
.price-table .pt-price-box {
  padding: 0;
  text-align: center;
  color: #ffffff;
}
.price-table .pt-price-box .pt-currency {
  font-size: 16px;
  vertical-align: top;
  display: inline-block;
  font-weight: 600;
}
.price-table .pt-price-box .pt-price {
  font-size: 56px;
  font-weight: 600;
  line-height: 47px;
}
.price-table .pt-price-box .pt-period {
  display: inline-block;
  vertical-align: middle;
}
.price-table .pt-price-box .pt-period:before {
  content: "/";
  margin-right: 5px;
}
.price-table .pt-content {
  padding: 25px 20px;
  border-bottom: 1px solid rgba(17, 17, 17, 0.06);
}
.price-table .pt-content ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
.price-table .pt-content li {
  list-style: none;
  position: relative;
  padding: 5px 0;
}
.price-table .pt-action {
  padding: 20px;
  text-align: center;
}
 */
/* Team
--------------------*/
/* .our-team {
  position: relative;
}
.our-team .team-img {
  overflow: hidden;
  border-radius: 5px;
}
.our-team .team-info {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  padding: 20px 15px;
  margin-top: -40px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  top: 0;
  border-radius: 5px;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.our-team .team-info h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
  color: #111111;
}
.our-team .team-info label {
  margin: 0 0 15px;
  font-weight: 400;
  line-height: normal;
  font-size: 15px;
}
.our-team:hover .team-info {
  top: -15px;
  box-shadow: 0px 30px 60px 0px rgba(38, 59, 94, 0.1);
} */

/* Contact us
--------------------*/
/* .contact-form {
  padding: 5%;
}
.contact-form .form-control {
  height: 45px;
  font-size: 14px;
  border-radius: 0;
  box-shadow: none !important;
}
.contact-form textarea.form-control {
  height: 150px;
} */

/* Blog
--------------------------------*/
/* .blog-grid {
  background: #ffffff;
}
.blog-list-page .blog-grid {
  margin-bottom: 30px;
}
.blog-grid .blog-grid-img {
  overflow: hidden;
}
.blog-grid .blog-grid-img img {
  -moz-transition: ease all 0.85s;
  -o-transition: ease all 0.85s;
  -webkit-transition: ease all 0.85s;
  transition: ease all 0.85s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog-grid .blog-gird-info {
  padding: 20px;
}
.blog-grid h5 {
  margin: 0;
  padding: 10px 0 15px;
  font-size: 22px;
  font-weight: 600;
  color: #111111;
}
.blog-grid h5 a {
  color: #111111;
}
.blog-grid h5 a:hover {
  color: #f34236;
}
.blog-grid p {
  margin: 0;
  font-size: 15px;
}
.blog-grid .b-meta span {
  font-size: 12px;
  color: #828282;
  line-height: 15px;
}
.blog-grid .b-meta .meta {
  display: inline-block;
  border-left: 1px solid #ddd;
  line-height: 15px;
  margin: 0 0 0 6px;
  padding: 0 0 0px 6px;
}
.blog-grid .btn-grid {
  margin-top: 15px;
}
.blog-grid:hover .blog-grid-img img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

/* @media (min-width: 992px) {
  .blog-sidebar {
    padding-left: 20px;
  }
} */
/* @media (max-width: 991px) {
  .blog-sidebar {
    padding-top: 35px;
  }
}
.widget {
  box-shadow: rgba(58, 78, 95, 0.2) 0 10px 16px,
    rgba(58, 78, 95, 0.05) 0 -5px 16px;
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
}
.widget .widget-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 25px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 12px;
  position: relative;
  color: #111111;
}
.widget .widget-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 38px;
  height: 3px;
  background: #f34236;
}
.widget .cat-list {
  margin: 0;
  padding: 0;
}
.widget .cat-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-direction: row;
  flex-direction: row;
}
.widget .cat-list li:not(:first-child) {
  padding: 8px 0 0;
  border-top: 1px solid #eee;
  margin: 8px 0 0;
}
.widget .cat-list li a {
  font-weight: 400;
  color: #111111;
  font-size: 14px;
}
.widget .cat-list li a:hover {
  color: #f34236;
}

.tags a {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 4px 15px;
  margin: 0 6px 8px 0px;
  color: #111111;
  border-radius: 5px;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.tags a:hover {
  background: #f34236;
  color: #ffffff;
  border-color: #f34236;
}

.widget-subscribe .form-group {
  margin-bottom: 0px;
}
.widget-subscribe .form-control {
  border: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: none !important;
  height: 45px;
  font-size: 14px;
}

.blog-pagination {
  padding-top: 15px;
}
.blog-pagination .nav a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #ddd;
  background: #ffffff;
  color: #111111;
  font-weight: 500;
  margin: 0 4px;
  border-radius: 3px;
  font-size: 14px;
}
.blog-pagination .nav a:hover,
.blog-pagination .nav a.active {
  background: #f34236;
  color: #ffffff;
  border-color: #f34236;
} */

/*------------------
*	Blog Heading
-------------------*/
/* .blog-entry-heading {
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
}
.blog-entry-heading .meta {
  text-align: center;
}
.blog-entry-heading .meta a {
  color: rgba(255, 255, 255, 0.8);
}
.blog-entry-heading .meta a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.blog-entry-heading .entry-title {
  text-align: center;
  margin: 25px 0 0;
}
.blog-entry-heading .entry-title h1 {
  font-size: 70px;
  margin: 0;
  color: #ffffff;
}
@media (max-width: 767px) {
  .blog-entry-heading .entry-title h1 {
    font-size: 40px;
  }
}
 */
/*------------------
*	Blog Content
-------------------*/
/* .blog-entry-content {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .blog-entry-content {
    padding-top: 30px;
  }
}
.blog-entry-content .container > * {
  margin-bottom: 20px;
}
.blog-entry-content h1,
.blog-entry-content h2,
.blog-entry-content h3,
.blog-entry-content h4,
.blog-entry-content h5,
.blog-entry-content h6 {
  font-weight: 600;
  color: #111111;
  margin-top: 30px;
}
 */
/*------------------
*	Blog tags
-------------------*/
/* .blog-entry-footer {
  padding: 20px 0 40px;
}
@media (max-width: 767px) {
  .blog-entry-footer {
    padding: 0 0 30px;
  }
} */

/*------------------
*	Blog Share
-------------------*/
/* .blog-share {
  padding: 0 0 50px;
}
@media (max-width: 767px) {
  .blog-share {
    padding: 0 0 30px;
  }
}

.share-icon li {
  border-top: 1px solid #eee; */
/* border-bottom: 1px solid #eee; */
/* }
.share-icon li + li {
  border-left: 1px solid #eee;
}
.share-icon li a {
  display: block;
  padding: 10px;
  color: #111111;
}
.share-icon li a i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 6px;
}
.share-icon li a:hover {
  color: #f34236;
} */

/*------------------
*	Blog Author
-------------------*/
/* .blog-author {
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .blog-author {
    padding-bottom: 30px;
  }
}

.blog-author-in {
  background: #f3f3f3;
  padding: 6%;
}
.blog-author-in .ba-img {
  overflow: hidden;
  border-radius: 50%;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 80px;
  height: 80px;
}
@media (max-width: 767px) {
  .blog-author-in .ba-img {
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    max-width: 60px;
    height: 60px;
  }
}
.blog-author-in .ba-info {
  padding: 0 0 0 30px;
}
@media (max-width: 767px) {
  .blog-author-in .ba-info {
    padding: 0 0 0 10px;
  }
}
.blog-author-in .ba-info h3 {
  font-size: 18px;
  font-weight: 600;
  color: #111111;
  margin: 0 0 10px;
}
.blog-author-in .ba-info p {
  margin: 0;
} */

/*------------------
*	Comments
-------------------*/
/* .comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.comment-list > li:first-child > .comment-body {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.comment-list .comment-body {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #ddd;
}
.comment-list .comment-meta .comment-author {
  overflow: hidden;
  border-radius: 50%;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  max-width: 50px;
  height: 50px;
}
.comment-list .comment-meta .comment-metadata {
  padding-left: 10px;
}
.comment-list .comment-meta .comment-metadata .c-name {
  color: #111111;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 5px;
}
.comment-list .comment-meta .comment-metadata .c-date {
  font-size: 12px;
  line-height: normal;
}
.comment-list .comment-content {
  padding: 10px 0 10px 60px;
}
.comment-list .comment-content p {
  margin: 0;
}
.comment-list .comment-reply {
  padding-left: 60px;
}
.comment-list .children {
  margin: 0;
  padding: 0 0 0 60px;
  list-style: none;
} */

/*------------------
*	Comments form
-------------------*/
/* .comment-respond-section {
  padding: 50px 0;
}
@media (max-width: 767px) {
  .comment-respond-section {
    padding-top: 30px;
  }
}

.comment-respond-form {
  background: #f3f3f3;
  padding: 30px;
}
.comment-respond-form .form-group > label {
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}
.comment-respond-form .form-control {
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
}
.comment-respond-form .form-control:focus {
  border: 1px solid #f34236;
}

.blockquote-left {
  padding: 0 20px;
  border-left: 5px solid #ddd;
  position: relative;
}
.blockquote-left .icon {
  font-size: 30px;
  color: #f34236;
  padding-bottom: 15px;
}
.blockquote-left p:last-child {
  margin-bottom: 0px;
}

.blockquote-theme {
  padding: 20px;
  position: relative;
  background: #f34236;
  color: #ffffff;
  border-radius: 5px;
}
.blockquote-theme .icon {
  font-size: 30px;
  color: #ffffff;
  padding-bottom: 15px;
}
.blockquote-theme p:last-child {
  margin-bottom: 0px;
}

.blockquote-dark {
  padding: 20px;
  position: relative;
  background: #111111;
  color: #ffffff;
  border-radius: 5px;
}
.blockquote-dark .icon {
  font-size: 30px;
  color: #ffffff;
  padding-bottom: 15px;
}
.blockquote-dark p:last-child {
  margin-bottom: 0px;
}

.blockquote-gray {
  padding: 20px;
  position: relative;
  background: #eee;
  color: #111111;
  border-radius: 5px;
}
.blockquote-gray .icon {
  font-size: 30px;
  color: #111111;
  padding-bottom: 15px;
}
.blockquote-gray p:last-child {
  margin-bottom: 0px;
}

.blockquote-center {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.blockquote-center i {
  font-size: 32px;
  margin-bottom: 20px;
  opacity: 0.2;
}
.blockquote-center p {
  font-size: 26px;
  line-height: 32px;
  padding: 0 70px;
  font-weight: 600;
  margin: 0 0 20px;
  color: #111111;
}
@media (max-width: 767px) {
  .blockquote-center p {
    font-size: 22px;
    line-height: 28px;
    padding: 0 20px;
  }
} */

/*# sourceMappingURL=master.css.map */
